<template>
  <div id="freetrial">
    <div class="text-h4 text-center">{{ $t('freeTrial.CTA') }}</div>
    <div class="text-p1 text-center">{{ $t('freeTrial.CTA2') }}</div>
    <div class="text-h6 text-center pb-5">{{ $t('freeTrial.instructions') }}</div>
    <v-form v-model="valid" ref="form">
      <v-stepper v-model="step" flat color="background">
        <v-stepper-header flat color="background">
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            {{ $t('freeTrial.userInfo') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="step > 2"
            step="2"
          >
            {{ $t('freeTrial.orgInfo') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items color="background">
          <v-stepper-content step="1" color="background">
            
            <v-text-field
              dense
              filled
              :label="$t('firstName')"
              name="firstname"
              prepend-icon="mdi-account"
              type="text"
              v-model="firstName"
              required
              :rules="$t('rules.firstNameRules')"
              autocomplete="new-password"
            ></v-text-field>
            <v-text-field
              dense
              filled
              :label="$t('lastName')"
              name="lastname"
              prepend-icon="mdi-account"
              type="text"
              v-model="lastName"
              required
              :rules="$t('rules.lastNameRules')"
              autocomplete="new-password"
            ></v-text-field>

            <v-text-field
              dense
              filled
              :label="$t('email')"
              name="email"
              prepend-icon="mdi-email"
              type="text"
              v-model="email"
              required
              :rules="$t('rules.emailRules')"
            autocomplete="new-password"
            ></v-text-field>

            <v-text-field
              dense
              filled
              :label="$t('password')"
              name="password"
              prepend-icon="mdi-lock-question"
              :type="show1 ? 'text' : 'password'"
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              required
              :rules="$t('rules.passRules')"
              autocomplete="new-password"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-btn :class="{ 'primary': !$vuetify.theme.dark }" :loading="creatingUser" block large @click="step = 2">{{ $t('next') }}</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2" background-color="background">
            <v-text-field
              dense
              filled
              v-model="orgName"
              :rules="$t('rules.fieldRules')"
              :label="$t('orgName')"
              required
            ></v-text-field>
            <v-autocomplete
              dense
              filled
              v-model="country"
              :items="countries"
              item-text="name"
              item-value="code"
              :label="$t('country')"
              :rules="$t('rules.countryRules')"
              required
            ></v-autocomplete>
            <v-text-field v-if="country == 'GT'"
              dense
              filled
              v-model="nit"
              :rules="$t('rules.fieldRules')"
              label="NIT"
              required
            ></v-text-field>
            <v-row align="center" justify="center">
              <v-col></v-col>
              <v-col class="text-center" id="recaptcha">
                <vue-recaptcha ref="reca"  @verify="onCaptchaVerified" sitekey="6LdewLwUAAAAAM7PCVIL1w8bd2RGKTBWdKcYe7eY" :theme="theme"></vue-recaptcha>
              </v-col>
              <v-col></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn :class="{ 'primary': !$vuetify.theme.dark }" :loading="creatingUser" block large @click="startFreeTrial">{{ $t('freeTrial.start') }}</v-btn>
              </v-col>
            </v-row>

            <v-btn text @click="step=1">
              {{ $t('back') }}
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>
    <v-row align-content="center" justify="center"> 
      <v-col cols="12" class="pb-0 pt-5">
        <div d-flex class="title text-center">{{ $t('or') }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-btn text color="primary" block to="/login">{{ $t('login') }}</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="accountCreated"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">{{ $t('congratulations') }}</v-card-title>

        <v-card-text>
          {{ $t('signupView.success') }}
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="green darken-1"
            text
            @click="toLogin()"
          >
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
var axios = require("axios")

export default {
  name: 'Signup',
  components: {
    VueRecaptcha
  },
  data: () => ({

    response:'',
    accountCreated: false,
    invalidForm: false,
    timeout: 6000,

    creatingUser: false,

    noCaptchaToken: false,

    show1: false,
    valid: true,

    step: 1,
    
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    orgName: null,
    country: null,
    nit: null,
    address: null,
    phonenumber: null,

    recaptchaToken: null,
    countries: [],
    // snackbar
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: ''

  }),
  computed: {
    theme() {
      if(this.$vuetify.theme.dark){
        return 'dark'
      }
      else{
        return 'light'
      }
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        return true
      }
      else{
        return false
      }
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },

    onCaptchaVerified: function (recaptchaToken) {
      let vm = this
      vm.recaptchaToken = recaptchaToken
    },
    
    startFreeTrial() {
      let vm = this
      vm.creatingUser = true
      this.validate()
      // eslint-disable-next-line
      const token =  vm.recaptchaToken
      if(this.valid){
        if(token){
          var obj = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            recaptcha: token,
            password: this.password,
            orgName: this.orgName,
            country: this.country
          }
          if(vm.nit != null){
            obj['nit'] = vm.nit
          }
          const data = JSON.stringify(obj)
          const config = {
            method: 'POST',
            url: process.env.VUE_APP_ENGINE+'auth/freetrial',
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          }
          // eslint-disable-next-line
          axios(config).then(function (response) {
            vm.firstName = null
            vm.email = null
            vm.lastName = null
            vm.password = null
            vm.orgName = null
            vm.address = null
            vm.phonenumber = null
            vm.country = null
            vm.nit = null
            vm.creatingUser = false
            vm.handleResponse(response.data['message'])
            // eslint-disable-next-line
            grecaptcha.reset()
            // eslint-disable-next-line
          }).catch(function (error) {
            // console.log(error)
            vm.$refs.reca.reset()
            vm.creatingUser = false
            console.log(error.response)
            vm.handleResponse(error.response.data['message'])
            // vm.setSnackbar("Check internet connection", "error")
          })
        }
        else{
          vm.setSnackbar(vm.$t('signupView.redoCaptcha'), 'error')
          this.$refs.reca.reset()
          vm.creatingUser = false
        }
      }
      else{
        vm.creatingUser = false
        this.setSnackbar(vm.$t('signupView.fillFields'), 'error')
      }
    },
    handleResponse(response){
      if(typeof response === 'string' ){
        if(response == 'USER_CREATED'){
          // this.accountCreated = true
          this.$router.push('thankYouSignUp')
        }
        else if(response === 'EMAIL_EXISTS'){
          this.$refs.reca.reset()
          this.setSnackbar(this.$t('signupView.emailExists'), 'orange')

        }
        else if(response === 'INVALID_CAPTCHA') {
          this.$refs.reca.reset()
          this.setSnackbar(this.$t('signupView.invalidCaptcha'), 'error')
        }
        else if(response === 'USER_CREATION_FAILED') {
          this.$refs.reca.reset()
          this.setSnackbar(this.$t('signupView.createFail'), 'error')
        }
        else{
          this.$refs.reca.reset()
          this.setSnackbar(this.$t('signupView.unkownErr'), 'error')
        }
      }
      this.creatingUser = false
    },
    toLogin(){
      this.$router.replace('/login')
    }
  },
  beforeMount() {
    // LOAD RECAPTCHE HERE
  },
  mounted() {
    this.$vuetify.goTo('#freetrial')
    this.countries = require('@/../countries.json')
  },
  watch: {
  }
}
</script>

<style>

</style>
