<template>
  <v-container id="homeDash" class="py-8">
    <div v-if="this.$store.getters.VERIFIED">
      <div v-if="this.$store.getters.HASORG">
        <div v-if="this.$store.getters.ROLES != null">
          <v-row justify="center">
            <v-col xl=8 lg=9 md=10 sm=12 cols=12 class="pa-0">
              <v-row align="stretch" justify="center" class="text-center">
                <v-col sm=6 cols=12 v-for="(item, idx) in cards" :key="idx" v-if="checkRoles(item.roles, $store.getters.ROLES)">
                  <Button :title="item.title" 
                  :description="item.description" 
                  :route="item.route"
                  :status="status"
                  :limited="item.limited"
                  :upgradeAvailable="item.permissions"
                  :roles="item.roles"
                  :icon="item.icon"></Button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else-if="this.$store.getters.HASORG === false">
        <v-row align="center" justify="center" height="100%">
          <v-col xl=5 lg=6 md=6 sm=10 cols=12>
            <v-card hover @click="goToOrg">
              <v-card-title>{{ $t('createOrg') }}</v-card-title>
              <v-card-text>{{ $t('createOrgDes') }}</v-card-text>
              <v-card-actions>
                <v-btn text disabled></v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <v-progress-circular :size="50" color="accent" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else-if="this.$store.getters.VERIFIED === false">
      <v-row justify="center">
        <v-col class="text-center"  xl=5 lg=5 md=5 sm=10 cols=12 >
          <div class="title">{{ $t('emailVer') }}</div>
          <br>
          <p>{{ $t('emailVerDes') }}</p>
          <br>
          <v-btn :loading="sendingEmail" large raised @click="sendEmailVer()">{{ $t('sendEmail') }}</v-btn>
        </v-col>
      </v-row>
    </div>
    
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Button from './homeDash/Button'
import firebase from 'firebase'

export default {
  name: 'HomeDash',
  components: {
    Button,
  },
  data: () => ({
    sendingEmail: true,
    timeout: 6000,
    // snackbar
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: '',
    status: null,
    permissions: null,
  }),
  computed: {
    cards () {
      var a = [
        {title: this.$t('processes'), description: this.$t('processesDes'), route: 'processes', limited: true, roles:['creator', 'owner', 'consultant'], permissions: this.upgradeAvailable(null, this.permissions), icon: 'mdi-file-document-box-multiple' },
        {title: this.$t('review'), description: this.$t('reviewsDes'), route: 'reviews', limited: true, roles:['reviewer', 'owner'], permissions: this.upgradeAvailable(['reviewProcess'], this.permissions), icon: 'mdi-message-draw'},
        {title: this.$t('organization'), description: this.$t('organizationDes'), route: 'manageorg', limited: false, roles:['owner'], icon: 'mdi-domain'},
        {title: this.$t('templates'), description: this.$t('templatesDes'), route: 'managetemplates', limited: true, roles:['manager', 'owner'], permissions: this.upgradeAvailable(['customTemplates'], this.permissions), icon: 'mdi-file-document-edit'}
      ]
      if(process.env.VUE_APP_DISPLAY_PAYMENTS != 'false'){
        a.push({title: this.$t('paymentsT'), description: this.$t('paymentsDes'), route: 'payments', limited: false, roles:['owner'], icon: 'mdi-credit-card-outline'})
      }
      a.push({title: this.$t('dashboardT'), description: this.$t('dashboardDes'), route: 'dashboard', limited: true, roles:['manager', 'owner'], permissions: this.upgradeAvailable(['dashboard'], this.permissions), icon: 'mdi-chart-line'})
      a.push({title: this.$t('alerts.title'), description: this.$t('alerts.description'), route: 'alerts', limited: true, roles:['creator', 'manager', 'reviewer', 'owner'], permissions: this.upgradeAvailable(['alerts'], this.permissions), icon: 'mdi-bell'})
      if(process.env.VUE_APP_DISPLAY_UTILITIES != 'false'){
        a.push({title: this.$t('utilities'), description: this.$t('utilitiesDes'), route: 'utilities', limited: true, roles:['creator', 'manager', 'reviewer', 'owner'], permissions: this.upgradeAvailable(['utilities'], this.permissions), icon: 'mdi-hammer-screwdriver'})
      }
      return a
      return [
        {title: this.$t('processes'), description: this.$t('processesDes'), route: 'processes', limited: true, roles:['creator', 'owner'], permissions: this.upgradeAvailable(null, this.permissions), icon: 'mdi-file-document-box-multiple' },
        {title: this.$t('review'), description: this.$t('reviewsDes'), route: 'reviews', limited: true, roles:['reviewer', 'owner'], permissions: this.upgradeAvailable(['reviewProcess'], this.permissions), icon: 'mdi-message-draw'},
        {title: this.$t('organization'), description: this.$t('organizationDes'), route: 'manageorg', limited: false, roles:['owner'], icon: 'mdi-domain'},
        {title: this.$t('templates'), description: this.$t('templatesDes'), route: 'managetemplates', limited: true, roles:['manager', 'owner'], permissions: this.upgradeAvailable(['customTemplates'], this.permissions), icon: 'mdi-file-document-edit'},
        {title: this.$t('paymentsT'), description: this.$t('paymentsDes'), route: 'payments', limited: false, roles:['owner'], icon: 'mdi-credit-card-outline'},
        {title: this.$t('dashboardT'), description: this.$t('dashboardDes'), route: 'dashboard', limited: true, roles:['manager', 'owner'], permissions: this.upgradeAvailable(['dashboard'], this.permissions), icon: 'mdi-chart-line'},
        {title: this.$t('alerts.title'), description: this.$t('alerts.description'), route: 'alerts', limited: true, roles:['creator', 'manager', 'reviewer', 'owner'], permissions: this.upgradeAvailable(['alerts'], this.permissions), icon: 'mdi-bell'},
        {title: this.$t('utilities'), description: this.$t('utilitiesDes'), route: 'utilities', limited: true, roles:['creator', 'manager', 'reviewer', 'owner'], permissions: this.upgradeAvailable(['utilities'], this.permissions), icon: 'mdi-hammer-screwdriver'},
        // {title: 'Proyectos Inmobiliarios', description: 'Administra proyectos inmobiliarios', route: 'HP', limited: false, roles:['creator', 'manager', 'reviewer', 'owner'], permissions: this.upgradeAvailable(['realState'], this.permissions), icon: 'mdi-office-building'},
      ]
    }
  },
  methods: {
    findCommonElements3(arr1, arr2) { 
      return arr1.some(item => arr2.includes(item)) 
    },
    checkRoles(roles, uRoles){
      return this.findCommonElements3(roles, uRoles)
    },
    goToContract () {
      if(!this.status) return
      this.$router.push('processes')
    },
    goToReviews () {
      if(!this.status) return
      this.$router.push('reviews')
    },
    goToTemplates () {
      this.$router.push('managetemplates')
    },
    goToOrg () {
      this.$router.push('manageorg')
    },
    goToPayments () {
      this.$router.push('payments')
    },
    goToDashboard () {
      if(!this.status) return
      this.$router.push('dashboard')
    },
    sendEmailVer () {
      let vm = this
      vm.sendingEmail = true
      var user = firebase.auth().currentUser
      user.sendEmailVerification().then(function () {
        // Email sent.
        vm.setSnackbar(vm.$t('emailSent'), 'success')
        vm.sendingEmail = false
      // eslint-disable-next-line
      }).catch(function (error) {
        // An error happened.
        vm.setSnackbar(vm.$t('emailSentError'), 'error')
        vm.sendingEmail = false
      })
    },
    getBasicInfo() {
      let vm = this
      // eslint-disable-next-line
      vm.$store.dispatch('GET_BASIC_INFO').then(() => {
      })
      // vm.$store.dispatch('SAVE_TOKEN')
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },
    upgradeAvailable(itemPermissions, permissions){
      if(permissions == null || itemPermissions == null){
        return false
      }
      var upgrade = false
      itemPermissions.forEach(ele =>{
        if(permissions[ele] == false){
          upgrade = true
        }
      })
      return upgrade
    }

  },
  mounted(){
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    sleep(6000).then(() => {
      this.sendingEmail = false
    })
    this.permissions = this.$store.getters.PERMISSIONS
    this.$vuetify.goTo(0)
    if(this.$store.getters.LASTROUTE != 'Login' && this.$store.getters.HASORG === null){
      // vm.getBasicInfo()
    }
    this.status = this.$store.getters.ACTIVE
    this.$store.watch(
      (state, getters) => getters.ACTIVE,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        this.status = newValue
      },
    )
    this.$store.watch(
      (state, getters) => getters.PERMISSIONS,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        this.permissions = newValue
      },
    )
  }
}
</script>
