<template>
  <HomeDash/>
</template>

<script>
import HomeDash from '../components/HomeDash'

export default {
  components: {
    HomeDash,
  },
  mounted() {
    if(localStorage.customTheme != null) {
      const newTheme = JSON.parse(localStorage.customTheme)
      for(var key in newTheme){
        this.$vuetify.theme.currentTheme[key] = newTheme[key]
      }
    }
  }
};
</script>
