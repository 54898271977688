<template>
  <div id="signup">
    <div class="text-h4 text-center pb-8">{{ $t('signUp') }}</div>
    <v-form v-model="valid" ref="form">
      <v-text-field
        filled
        :label="$t('firstName')"
        name="firstname"
        prepend-icon="mdi-account"
        type="text"
        v-model="firstName"
        required
        :rules="$t('rules.firstNameRules')"
        autocomplete="new-password"
      ></v-text-field>
      <v-text-field
        filled
        :label="$t('lastName')"
        name="lastname"
        prepend-icon="mdi-account"
        type="text"
        v-model="lastName"
        required
        :rules="$t('rules.lastNameRules')"
        autocomplete="new-password"
      ></v-text-field>

      <v-text-field
        filled
        :label="$t('email')"
        name="email"
        prepend-icon="mdi-email"
        type="text"
        v-model="email"
        required
        :rules="$t('rules.emailRules')"
       autocomplete="new-password"
      ></v-text-field>

      <v-text-field
        filled
        :label="$t('password')"
        name="password"
        prepend-icon="mdi-lock-question"
        :type="show1 ? 'text' : 'password'"
        v-model="password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show1 = !show1"
        required
        :rules="$t('rules.passRules')"
        autocomplete="new-password"
      ></v-text-field>
    </v-form>
    <v-row align="center" justify="center">
      <v-col></v-col>
      <v-col class="text-center" id="recaptcha">
        <vue-recaptcha ref="reca"  @verify="onCaptchaVerified" sitekey="6LdewLwUAAAAAM7PCVIL1w8bd2RGKTBWdKcYe7eY" :theme="theme"></vue-recaptcha>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :class="{ 'accent': !$vuetify.theme.dark }" :loading="creatingUser" block large @click="signUp">{{ $t('signUp') }}</v-btn>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col class="py-0">
        <v-btn text @click="otherProviders=true">
          <v-icon>mdi-google</v-icon>
          <v-icon>mdi-microsoft</v-icon>
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row align-content="center" justify="center"> 
      <v-col cols="12">
        <div d-flex class="title text-center">{{ $t('or') }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-btn text color="accent" block to="/login">{{ $t('login') }}</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="accountCreated"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">{{ $t('congratulations') }}</v-card-title>

        <v-card-text>
          {{ $t('signupView.success') }}
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="green darken-1"
            text
            @click="toLogin()"
          >
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="otherProviders" max-width="400">
      <v-card class="pa-3">
        <div div id="firebaseui-auth-container"></div>
        <div id="loader">Loading...</div>
      </v-card>
    </v-dialog>
    <!-- <div class="d-none">
      <div div id="firebaseui-auth-container2"></div>
      <div id="loader2">Loading...</div>
    </div> -->
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
var axios = require("axios")
import {firebase, auth, firebaseui} from '@/services/firebase'
import { reduceRight } from 'ramda'
export default {
  name: 'Signup',
  components: {
    VueRecaptcha
  },
  data: () => ({

    response:'',
    accountCreated: false,
    invalidForm: false,
    timeout: 6000,

    creatingUser: false,

    noCaptchaToken: false,

    show1: false,
    valid: true,
    otherProviders: false,

    firstName: null,
    lastName: null,
    email: null,
    password: null,
    recaptchaToken: null,
    // snackbar
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: ''

  }),
  computed: {
    theme() {
      if(this.$vuetify.theme.dark){
        return 'dark'
      }
      else{
        return 'light'
      }
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        return true
      }
      else{
        return false
      }
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },

    onCaptchaVerified: function (recaptchaToken) {
      let vm = this
      vm.recaptchaToken = recaptchaToken
    },
    
    signUp() {
      let vm = this
      vm.creatingUser = true
      this.validate()
      // eslint-disable-next-line
      const token =  vm.recaptchaToken
      if(this.valid){
        if(token){
          const data = JSON.stringify({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            recaptcha: token,
            password: this.password
          })
          const config = {
            method: 'POST',
            url: process.env.VUE_APP_ENGINE+'auth/signup',
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          }
          // eslint-disable-next-line
          axios(config).then(function (response) {
            vm.firstName = null
            vm.email = null
            vm.lastName = null
            vm.password = null
            vm.creatingUser = false
            vm.handleResponse(response.data['message'])
            // eslint-disable-next-line
            grecaptcha.reset()
            // eslint-disable-next-line
          }).catch(function (error) {
            vm.creatingUser = false
            vm.setSnackbar("Check internet connection", "error")
            vm.handleResponse(error.response.data['message'])
            return false
          })
        }
        else{
          vm.setSnackbar(vm.$t('signupView.redoCaptcha'), 'error')
          this.$refs.reca.reset()
          vm.creatingUser = false
        }
      }
      else{
        vm.creatingUser = false
        this.setSnackbar(vm.$t('signupView.fillFIelds'), 'error')
      }
    },
    handleResponse(response){
      if(typeof response === 'string'){
        if(response == 'USER_CREATED'){
          // this.accountCreated = true
          this.$router.push('thankYouSignUp')
        }
        else if(response === 'EMAIL_EXISTS'){
          this.$refs.reca.reset()
          this.setSnackbar(this.$t('signupView.emailExists'), 'orange')

        }
        else if(response === 'INVALID_CAPTCHA') {
          this.$refs.reca.reset()
          this.setSnackbar(this.$t('signupView.invalidCaptcha'), 'error')
        }
        else if(response === 'USER_CREATION_FAILED') {
          this.$refs.reca.reset()
          this.setSnackbar(this.$t('signupView.createFail'), 'error')
        }
        else{
          this.$refs.reca.reset()
          this.setSnackbar(this.$t('signupView.unkownErr'), 'error')
        }
      }
      this.creatingUser = false
    },
    toLogin(){
      this.$router.replace('/login')
    },
    startFirebaseUI(start=false){
      let vm = this
      if(firebaseui.auth.AuthUI.getInstance()) {
        this.ui = firebaseui.auth.AuthUI.getInstance()
      }
      else {
        this.ui = new firebaseui.auth.AuthUI(auth);
      }
      var uiConfig = {
        signInFlow: 'popup',
        callbacks: {
          signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            console.log(authResult)
            console.log(redirectUrl)
            vm.$router.replace('/home')
            vm.logging = false
            return false;
          },
          uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById('loader').style.display = 'none';
          }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        // signInFlow: 'popup',
        // signInSuccessUrl: window.location.href.slice(0, window.location.href.length - 6)+'home',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          'microsoft.com'
        ],
        // Terms of service url.
        tosUrl: 'https://contractflows.com/terms',
        // Privacy policy url.
        privacyPolicyUrl: 'https://contractflows.com/privacy'
      };
      if(start){
        this.ui.start('#firebaseui-auth-container2', uiConfig)
      }
      else{
        this.ui.start('#firebaseui-auth-container', uiConfig)
      }
      
      
    }
  },
  beforeMount() {
    // LOAD RECAPTCHE HERE
  },
  mounted() {
    this.$vuetify.goTo('#signup')
    // this.startFirebaseUI(true)
  },
  watch: {
    otherProviders: function() {
      if(this.otherProviders){
        // setTimeout(() => {  this.startFirebaseUI() }, 1000);
      }
    }
  }
}
</script>

<style>

</style>
