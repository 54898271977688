<template>
  <div>
    <v-card>
      <v-card-title style="word-break: normal" class="text-h5">{{ $t('incompleteUser.title') }}</v-card-title>
      <v-card-text>{{ $t('incompleteUser.text') }}</v-card-text>
      <v-card-text>
        <v-form v-if="needPassword == false" v-model="valid" ref="form">
          <v-text-field
            filled
            :label="$t('firstName')"
            name="firstname"
            prepend-icon="mdi-account"
            type="text"
            v-model="firstName"
            required
            :rules="$t('rules.firstNameRules')"
          ></v-text-field>
          <v-text-field
            filled
            :label="$t('lastName')"
            name="lastname"
            prepend-icon="mdi-account"
            type="text"
            v-model="lastName"
            required
            :rules="$t('rules.lastNameRules')"
          ></v-text-field>
          <v-row justify="center" class="text-center">
            <v-col>
              <v-btn color="primary darken-0" :disabled="!valid" :loading="settingInfo" @click="setUpInfo">{{ $t('incompleteUser.setUp') }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <div v-else-if="needPassword == true" class="text-center">
          <div>
            <v-icon large color="warning">
              mdi-alert-outline
            </v-icon>
          </div>
          {{ $t('incompleteUser.needPassword') }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="logout">{{$t('logOut')}}</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
  
</template>


<script>
import {firebase } from '@/services/firebase'
var axios = require("axios")
export default {
  name: 'infoForm',
  data: () => ({
    show1: false,
    valid: false,
    needPassword: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    settingInfo: false,
    googleProvider: false,
    // snackbar
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: '',
  }),
  computed: {

  },
  methods: {
    logout () {
      // firebase.auth().signOut().then(() => {
      //   this.$store.dispatch('RESET_EVERYTHING').then(() => {
      //     this.$router.replace('login')
      //     this.navItems = []
      //   })
      // })
      this.$store.dispatch('RESET_EVERYTHING').then(() => {
        firebase.auth().signOut().then(() => {
          this.$router.replace('login')
          this.loggedIn = false
        })
      })
    },
    setSnackbar (text, color, timeout = 10000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },
    getSignInMethods() {
      firebase.auth().fetchSignInMethodsForEmail(firebase.auth().currentUser.email).then((signInMethods) => {
        const emailPass = signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1
        const emailLink = signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1
        const googleProvider = signInMethods.indexOf('google.com') != -1
        const microsoftProvider = signInMethods.indexOf('microsoft.com') != -1
        console.log(signInMethods)
        if (emailPass) {
          // User can sign in with email/password.
          this.needPassword = false
        }
        else if (emailLink && !emailPass) {
          // User can sign in with email/link.
          this.needPassword = true
          this.resetPassword()
        }
        else if(googleProvider || microsoftProvider){
          this.googleProvider = true
          this.needPassword = false
        }
      // eslint-disable-next-line
      }).catch((error) => {
        // Some error occurred, you can inspect the code: error.code
      })
    },
    resetPassword(){
      this.sendingEmail = true
      var auth = firebase.auth()
      var user = auth.currentUser
      var emailAddress = user.email
      var vm = this
      auth.sendPasswordResetEmail(emailAddress).then(function() {
        vm.setSnackbar(vm.$t('incompleteUser.passResetEmailSent'), 'success')
        vm.sendingEmail = false
        // Email sent.
      // eslint-disable-next-line
      }).catch(function(error) {
        vm.setSnackbar(vm.$t('incompleteUser.passResetEmailError'), 'error')
        vm.sendingEmail = false
        // An error happened.
      })
    },
    setUpInfo() {
      if(!this.valid) {
        this.$refs.form.validate()
        return
      }
      this.settingInfo = true
      let vm = this
      var data = JSON.stringify({
        uid:  vm.$store.getters.UID,
        token: vm.$store.getters.TOKEN,
        firstName: vm.firstName,
        lastName: vm.lastName
      })
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_ENGINE+'user/incomplete',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(function (response) {
        vm.settingInfo = false
        vm.setSnackbar(vm.$t('incompleteUser.success'), 'success')
        // eslint-disable-next-line
        window.location.href = window.location.href
        // eslint-disable-next-line
      }).catch(function (error) {
        vm.settingInfo = false
        vm.setSnackbar(vm.$t('incompleteUser.error'), 'error')
        return false
      })
      this.settingInfo = false
      
    }
  },
  mounted() {
    this.getSignInMethods()
  }

}
</script>

<style>

</style>
