const login = {
  en:{
    fPass: "Forgot your password?"
  },
  es: {
    fPass: "¿Olvidasate tu contraseña?"
  }
}

export {
  login
}