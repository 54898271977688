import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {login} from './i18components/login.js'
var merge = require('lodash.merge')

Vue.use(VueI18n)

var messages = {
  en: {
    customizable: 'You can write your own value here',
    initiatedBy: 'Initiated by',
    consultant: 'Consultant',
    publicForm: 'Public Form',
    upload: 'Upload',
    internal: 'Internal',
    public: 'Public',
    add: 'Add',
    security: 'Security',
    start: 'Start',
    success: 'Success',
    error: 'Something went wrong',
    next: 'Next',
    back: 'Back',
    completedDate: 'Completed date',
    assignmentDate: 'Assignment date',
    download: 'Download',
    client: 'Client',
    emailExample: 'example@company.com',
    pending: 'Pending',
    done: 'Done',
    daysLeft: 'Days Left',
    open: 'Open',
    continue: 'Continue',
    query: 'Query',
    time: 'Time',
    from: 'From',
    worksAt: 'Works at',
    groups: 'Groups',
    utilities: 'Utilities',
    utilitiesDes: 'Tools that facilitate your work',
    sendCode: 'Send Code',
    enterCodeHere: 'Enter your code here',
    verificationCodeSent: 'An SMS has been sent to your phone with the verification code.',
    update: 'Update',
    themeChange: 'Change theme color',
    team: 'Team',
    noData: 'No data available',
    selectTeam: 'Select Team',
    selectTeamHint: 'This will make the process private to the team selected',
    plans: 'Subscription Plans',
    upgradeSubPlan: 'Upgrade Subscription Plan',
    pricing: 'Pricing',
    generateBatch: 'Generate batch',
    upgrade: 'Upgrade',
    waiting: 'Waiting',
    sent: 'Sent',
    completed: 'Completed',
    date: 'Date',
    message: 'Message',
    edit: 'Edit',
    copy: 'Copy',
    delete: 'Delete',
    deleted: 'Deleted',
    optionalGroups: 'Optional Groups',
    fields: 'Fields',
    multiple: 'Multiple',
    required: 'Required',
    groupName: 'Group Name',
    hint: 'Hint',
    options: 'Options',
    status: 'Status',
    project: 'Project',
    template: 'Template',
    total: 'Total',
    yes: 'Yes',
    no: 'No',
    email: 'Email',
    emailConf: 'Email confirmation',
    password: 'Password',
    enterPassword: 'Enter your password',
    fPass: 'Forgot your password?',
    fPass2: 'Forgot your password?',
    or: 'or',
    goToDocs: 'Go To Docs',
    login: 'Login',
    signUp: 'Sign Up',
    firstName: 'First Name',
    lastName: 'Last Name',
    disabled: 'Disabled',
    close: 'Close',
    search: 'Search',
    title: 'Title',
    name: 'Name',
    description: 'Description',
    timeCreated: 'Created On',
    logOut: 'Log Out',
    actions: 'Actions',
    country: 'Country',
    orgName: 'Organization Name',
    address: 'Address',
    phoneNumber: 'Phone Number',
    deactivate: 'Deactivate',
    directory: 'Directory',
    congratulations: 'Congratulations!',
    ok: 'Ok',
    cancel: 'Cancel',
    save: 'Save',
    accept: 'Accept',
    // homeDash
    home: 'Home',
    processes: 'Processes',
    processesDes: 'Manage processes',
    review: 'Review',
    reviewsDes: 'Review processes',
    organization: 'Organization',
    organizationDes: 'Manage organization and roles',
    templates: 'Templates',
    templatesDes: 'Manage templates',
    paymentsT: 'Subscription Plans',
    paymentsDes: 'Billing and Payments',
    dashboardT: 'Dashboard',
    dashboardDes: 'Monitor productivity',
    createOrg: 'Create or Join an Organization',
    createOrgDes: 'Create a new organization or accept an invite from an existing one',
    emailVer: 'Please verify your email',
    emailVerDes: 'Check your inbox to verify your email address. Then try to login again. If you can\'t find the email, you can send another one by clicking on the button',
    sendEmail: 'Send email!',
    account: 'Account',
    emailSent: 'Email is on its way, please check your inbox!',
    emailSentError: 'An error occurred sending the email verification',
    attentionRequired: 'Your attention is required to activate your organization',
    attentionRequired2: 'Please ask your organization administrator to solve payment information',
    goToPayments: 'Go to payments',

    // contract view
    startProcess: 'Start a process',
    yourDrafts: 'Your Drafts',
    reviseRejected: 'Revise Rejected',
    generateApproved: 'Generate Approved',

    // login view
    recoverMsg: 'We\'ll send a recovery link to:',
    sendRecover: 'Send recovery link',
    loginView: {
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
      loginErr: 'Invalid credentials',
      recoverSuc: 'Recovery email sent, check your inbox',
      recoverErr: 'An error occurred sending the recovery email',
      userDisabled: 'Disabled user, contact us',
      invalidEmail: 'Check your email address for errors',
      networkFailed: 'Check your internet conection'
    },
    signupView: {
      redoCaptcha: 'Please redo the captcha',
      fillFields: 'Please properly fill the fields',
      emailExists: 'Email already exists, try logging in',
      invalidCaptcha: 'Invalid captcha, please check the box again',
      createFail: 'Something went wrong, please reload the page',
      unkownErr: 'Unkown error, please refresh the page',
      success: 'Your first step towards a simpler and more productive process. Now just check your email to to verify your address.',
      otherProviders: 'Use other providers'
    },

    rules: {
      fileAttachmentRulesRequired: [
        v => !!v || 'The file is required',
        v => !v || v.size < 5000000 || 'File must be less than 5Mb',
      ],
      fileAttachmentRules: [
        v => !v || v.size < 5000000 || 'File must be less than 5Mb',
      ],
      guidRules: [
        v => !!v || 'Please enter your API Key',
        // v => /^(((?=.*}$){)|((?!.*}$)))((?!.*-.*)|(?=(.*[-].*){4}))[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}?[}]?$/.test(v) || 'Example: 123e4567-h89b-12d3-a456-9AC7CBDCEE52',
      ],
      fullNameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 60) || 'Name must be less than 60 characters',
      ],
      verifyCodeRules: [
        v => !!v || 'Please write a your code',
        v => (v && v.length == 6) || 'Enter 6 digits',
        v => /[0-9]{6}/.test(v) || 'Use only digits',
      ],
      singleNumberRules: [
        v => !!v || 'Please write a valid number',
      ],
      firstNameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 25) || 'Name must be less than 25 characters',
      ],
      lastNameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 25) || 'Name must be less than 25 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
      passRules: [
        v => (v && v.length >= 8) || 'Password must be at least 8 characters long',
        v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
        v => /(?=.*\d)/.test(v) || 'Must have one number',
        v => /([ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(v) || 'Must have one special character, ex:[!@#$%_]'
      ],
      titleRules: [
        v => !!v || 'Title is required',
      ],
      fieldRules: [
        v => !!v || 'Field is required',
      ],
      numberRules: [
        v => !!v || 'Field is required',
        v =>  /^((\d{1,3}(,\d{3})*(\.[\d]+)?)|(^[\d]+)(\.[\d]+)?)( ((\d{1,3}(,\d{3})*)(\.[\d]+)?|([\d]+)(\.[\d]+)?))*$/g.test(v) || 'Numbers can be described with commas and separated with one whitespace ex: 1,234.56 7890',
      ],
      codeRules: [
        v => !!v || 'Field is required',
        // eslint-disable-next-line
        v =>  /^[a-zA-Z\d-_\/.áéíóúÁÉÍÓÚ]+(( [a-zA-Z\d-_\/.áéíóúÁÉÍÓÚ]+)?)*$/g.test(v) || 'Codes can be described with letters, numbers, "-", "_", ".", and "/"',
      ],
      currencyRules: [
        v => !!v || 'Field is required',
        v =>  /^((\d{1,3}(,\d{3})*)|[\d]+)(\.[0-9]{2})$/g.test(v) || 'Field must have 2 decimals and units can be separated with commas  ex: 1500.21 or 1,500.21',
      ],
      countryRules: [
        v => !!v || 'Country is required'
      ],
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (v && v.length == 8) || 'Phone must at least 8 numbers long',
        v => /[0-9]{8}/g.test(v) || 'Phone number must be valid',
      ],
      phoneRules2: [
        // v => (v && v.length == 8) || 'Phone must at least 8 numbers long',
        v => /[0-9]{8}/g.test(v) || 'Phone number must be valid',
      ],
      inputRules: [
        value => !!value || 'You need to select a file'
      ],
      descriptionRules: [
        v => !!v || 'Field is required',
        v => (v && v.length <= 50) || 'Description must be less than 50 characters',
      ],
      directoryRules: [
        // v => !!v || 'Field is required',
        v => /^(|(\/\w+( \w+)*)+)$/.test(v) || 'Directory is invalid. eg: /abc/123 or leave blank',
      ],
      optionGroupRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 2) || 'Select at least 2 options',
      ],
      optionGroupFieldRequired: [
        v => !!v || 'Field is required',
      ],
      optionGroupFieldRM: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 1) || 'Select at least 1 option',
      ],
      loopRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 1) || 'Add at least 1 element',
      ]
    },

    // create component,
    create: {
      title: 'Start a new Process',
      tableTitle: '{org} Templates',
      tableView: 'Table View',
      directoryView: 'Directory View',
      name: 'Name',
      description: 'Description',
      timeCreated: 'Created At',
      newProcessTitle: 'New Process Title',
      saveDraft: 'Save Draft',
      sendForReview: 'Send For Review',
      sendDialogTitle: 'Do you want to start a new process?',
      sendDialogText: 'Please check all fields are filled correctly. This will start a review process needed for document generation.',
      startProcess: 'Start Process',
      saveDialogTitle: 'Do you want to save a new draft?',
      saveDialogText: 'You will be able to continue working on your new process later.',
      saveProcess: 'Save Draft',
      checkErrors: 'Please check the form for errors',
      fieldMissing: 'An error occurred while setting generated fields',
      attachmentMissing: 'You need to upload the required attached files',
      sendSuccess: 'Process created successfully!',
      sendFail: 'An error occurred creating the new process',
      saveSuccess: 'Draft saved successfully!',
      saveFail: 'An error occurred saving the draft',
      tagProcess: 'Add tags to the process',
      assignProject: 'Assign to a project',
      approveProcess: 'Approve Process',
      numberFieldError: 'Check number fields!',
      codeFieldError: 'Check code fields!',
      currencyFieldError: 'Check currency fields!',
      updateBatch: 'Upgrade your subscription plan to generate a batch of documents',
      approveProcessTitle: 'Do you want to approve this process?',
      approveProcessText: 'Please check all fields are filled correctly. After the process is approved, you won\'t be able to modify it.',
      upgradePlan: 'You can greatly reduce errors through a revision process by upgrading your subscription plan.',
      upgradeDialogTitle: 'Do you want to leave before saving your info?',
      upgradeDialogText: 'The information you entered to start this process will be lost.',
      batchDialogTitle: 'Batch Generate',
      getPublicUrl: 'Share Link',
      triedToSendToReview: 'This process needs at least one file attachment before sending it to review. Please save it first as a draft and then attach the files.',
      triedToSendToReviewTitle: 'Template requires attached files'
    },
    // drafts component
    drafts: {
      startRequest: 'Start a request',
      requestDialogTitle: '¿Do you want to start a request for this process?',
      requestDialogText: '',
      title: 'Continue your drafting Process',
      tableTitle: 'Drafts',
      sendForReview: '@:create.sendForReview',
      sendDialogTitle: 'Do you want to start a new process?',
      sendDialogText: 'Please check all fields are filled correctly. This will start a review process needed for document generation.',
      startProcess: 'Start Process',
      saveDialogTitle: 'Do you want to save the current draft?',
      saveDialogText: 'You will be able to continue working on your new process later.',
      saveDraft: 'Save Draft',
      deleteDialogTitle: 'Do you want to delete your draft?',
      deleteDialogText: 'The draft will be deleted and you won\'t be able to recover it.',
      deleteDraft: 'Delete',
      errorDecrypt: 'Error decrypting answers',
      saveSuccess: 'Draft saved successfully!',
      saveFail: 'An error occurred saving the draft',
      checkErrors: 'Please check the form for errors',
      fieldMissing: 'An error occurred while setting generated fields',
      attachmentMissing: 'You need to upload the required attached files',
      sendSuccess: 'Process created successfully!',
      sendFail: 'An error occured creating the process',
      deleteSuccess: 'Draft deleted successfully!',
      deleteFail: 'An error occured deleting the draft',
      deleteFailSelected: 'No draft selected to delete',
      copyDialogTitle: 'Select a draft to copy the filled information to',
      copyDialogText: 'This will copy the filled information to the draft selected below:',
      copy: 'Copy',
      copySuccess: 'Filled information copied!',
      copyError: 'An error occurred copying the information!',
      updateShare: 'Upgrade your subscription to share a form to others',
      updatePublic: 'Upgrade your subscription to start processes with public form links',
      updateFileAttachments: 'Upgrade your subscription to attach files to processes',
      shared: 'Shared Forms',
      createShared: 'Create a new shared form',
      shareForm: 'Share Form', 
      sharedDetailTitle: 'Shared Form with {name} at email {email}',
      useAnswers: 'Use Answers',
      warningAttachments: 'Files are uploaded by external users. Always scan for viruses.',
      noAttachments: 'This process is not configured for an attachment.'
    },
    unassigned: {
      short: 'Inbox',
      title: 'Unassigned Processes',
      tableTitle: 'Processes',
      deleteDialogTitle: 'Do you want to delete this public initiated process?',
      deleteDialogText: 'The unassigned process {title} will be deleted',
      delete: 'Delete',
      assign: 'Assign Process',
      assignDialogTitle: 'Assign process to a member',
      assignDialogText: 'Select a member from the organization to assign this process to',
      assignSuccess: 'Process assigned successfully',
      assignError: 'An error occurred',
      clientInfo: 'Client Info'
    },
    // rejected component
    rejected: {
      title: 'Revise rejected Processes',
      tableTitle: 'To Revise',
      sendForReview: 'Resend for Review',
      reviewedOn: 'Reviewed On',
      motive: 'Rejection motive:',
      sendDialogTitle: 'Do you want to send this process to review?',
      sendDialogText: 'Please check all fields are filled correctly. This process needs approval to generate the document.',
      sendProcess: 'Send Process',
      deleteDialogTitle: 'Do you want to delete the process?',
      deleteDialogText: 'The process with title {title} will be deleted and you won\'t be able to recover it.',
      deleteRejected: 'Delete',
      errorDecrypt: 'Error decrypting answers',
      checkErrors: 'Please check the form for errors',
      fieldMissing: 'Please check form for missing fields',
      attachmentMissing: 'You need to upload the required attached files',
      sendSuccess: 'Process sent for review successfully!',
      sendFail: 'An error occurred sending the process',
      deleteSuccess: 'Process deleted successfully!',
      deleteFail: 'An error occurred deleting the process',
      deleteFailSelected: 'No process selected to delete'
      
    },
    // approved component
    approved: {
      title: 'Generate Document',
      tableTitle: 'Approved Processes',
      generate: 'Generate Document',
      reviewedOn: '@:rejected.reviewedOn',
      generateDialogTitle: 'Do you want to generate this document?',
      generateDialogText: 'The process with title {title} will be generated and downloaded to your device',
      generateAction: 'Generate',
      errorDecrypt: 'Error decrypting answers',
      generateSuccess: 'The document has been generated, check your downloads!',
      generateFail: 'An error occurred generating the document',
      copyDialogTitle: 'Select a draft to copy the filled information to',
      copyDialogText: 'This will copy the filled information from the approved process to the draft selected below:',
      copy: 'Copy',
      copySuccess: 'Filled information copied!',
      copyError: 'An error occurred copying the information!',
      deleteSuccess: 'Process deleted successfully!',
      deleteFail: 'An error occurred deleting the process',
      deleteFailSelected: 'No process selected to delete',
      deleteDialogTitle: 'Do you want to delete the approved process?',
      deleteDialogText: 'The process with title {title} will be deleted and you won\'t be able to recover it.',
      delete: 'Delete',
      reviewer: 'Reviewer',
      createAlert: 'Create Alert',
      createAlertTitle: 'Create a new alert',
      alertError: 'Error creating new alert',
      alertSuccess: 'Alert successfully created!',
      pressEnter: 'Press enter to add email.',
      deleteAlertTitle: 'Do you want to delete the alert?',
      deleteAlertText: 'The alert with name {name} will be deleted.',
      deleteAlert: 'Delete Alert',
      deleteAlertSuccess: 'Alert successfully deleted',
      deleteAlertError: 'An error occurred deleting the alert',
      aEmails: 'Additional emails',
      updateAlerts: 'Upgrade your subscription to create alerts',
      completedDateSet: 'Date updated'
    },

    // reviews view
    reviews: {
      title: 'Processes to Review',
      tableTitle: 'To Review',
      reject: 'Reject',
      approve: 'Approve',
      approveDialogTitle: 'Do you want to approve this process?',
      approveDialogText: 'The process with title {title} will be approved for generation. Please make sure the filled info is correct.',
      rejectDialogTitle: 'Do you want to reject this process?',
      rejectDialogText: 'The process with title {title} will be sent for revision. Please make sure the filled info is correct and reason is understandable.',
      checkErrors: 'Please check form for errors',
      approveSuccess: 'The process has been approved successfully!',
      approveFail: 'An error occurred approving the process',
      resonRejection: 'Please write a reason for rejection',
      rejectSuccess: 'The process has been rejected successfully!',
      rejectFail: 'An error occurred rejecting the process',
      errorDecrypt: 'Error decrypting answers',
      reason: 'Reason to reject process',
      creator: 'Creator'
    },

    // myOrgComponent
    myOrg: {
      manageUsers: 'Manage Users',
      manageClientsAndProjects: 'Clients and Projects',
      manageESignatures: 'Electronic Signature',
      newClientTitle: 'New Client',
      createClient: 'Save Client',
      deleteClientDialogTitle: 'Do you want to delete this client?',
      deleteClientDialogText: 'The client {name} will be deleted and will not be referenced in the future.',
      deleteClientSuccess: 'Client deleted successfully!',
      deleteClientError: 'An error occurred deleting the client',
      editClientTitle: 'Edit',
      editClientSuccess: 'Client saved successfully!',
      editClientError: 'An error occurred editing the client',
      individual: 'Individual',
      individualHint: 'The user will only have access to the processes they started',
      inviteTitle: 'Invite User to Organization',
      usersInOrgTitle: 'Users in the Organization',
      reviewer: 'Reviewer',
      creator: 'Creator',
      manager: 'Manager',
      owner: 'Owner',
      consultant: 'Consultant',
      roles: 'Roles',
      hasAccepted: 'Has Accepted',
      inviteUser: 'Invite User',
      oneRole: 'Please select at least one role',
      inviteDialogTitle: 'Do you want to invite user to your organization?',
      inviteDialogText: 'The user with the email {email} will receive an invite to join your organization. Please make sure this is the correct email address. This user will gain access to your organization functions once access is given.',
      invite: 'Invite',
      active: 'Active',
      billing: 'Billing',
      editRoles: 'Edit roles',
      deleteDialogTitle: 'Remove user from your organization?',
      deleteDialogText: 'The user with the email {email} will have its access revoked and will no longer have access to your organization\'s functions',
      remove: 'Remove',
      inviteError: 'Error inviting user',
      inviteUserErrorLimit: 'Please upgrade your subscription',
      inviteSuccess: 'Successfully invited user',
      removeError: 'Error removing member',
      removeSuccess: 'Successfully removed member',
      editFail: 'Failed editing user in organization',
      editSuccess: 'Successfully modified user in organization',
      projects: 'Projects',
      deleteProjectDialogTitle: 'Do you want to delete this project?',
      deleteProjectDialogText: 'The project {name} will be deleted.',
      delete: 'Delete',
      deleteProjectSuccess: 'Project deleted successfully!',
      deleteProjectError: 'An error occurred deleting the project',
      newProjectDialogTitle: 'Create a new project',
      createProject: 'Create Project',
      createProjectSuccess: 'Project created successfully',
      createProjectError: 'An error occurred creating the project',
      createProjectErrorExists: 'Project name already exists',
      appSettings: {
        title: 'App Settings',
      },
      clients: {
        title: 'Clients',
        createSuccess: 'Client created successfully',
        createError: 'An error occurred creating the client',
        createErrorExists: 'Client name already exists',
        deleteSuccess: 'Client deleted successfully',
        deleteError: 'An error occurred deleting the client',
      },
      teams: {
        title: 'Workspace Teams',
        members: 'Members',
        newTitle: 'Create a new Team',
        create: 'Create Team',
        createExists: 'Team name already exists, please use another one',
        createError: 'An error occurred creating the Team',
        createSuccess: 'Team created successfully',
        delTitle: 'Do you want to delete this team?',
        delText: 'The organization\'s owner will continue to have access to the processes associated with the team.',
        delTeamText: 'The following users in your organization will loose access to any processes, at any state, associated with the team',
        del: 'Delete Team',
        confirmDel: 'Please write "DELETE" to continue:',
        confirmDelWord: 'DELETE',
        delTeamSuccess: 'Team deleted successfully',
        delTeamError: 'An error occurred deleting the team.',
        teamNotSelected: 'Team is not selected',
        editTitle: 'Team Configuration',
        editSave: 'Save',
        addMember: 'Add Member',
        add: 'Add',
        invalidNewMember: 'New member is invalid',
        invalidTeamName: 'New team name is invalid',
        addMemberTeamError: 'An error occurred adding a new member to team (team error)',
        addMemberRoles: 'An error occurred adding a new member to team',
        addMemberExists: 'Member is already in team',
        addMemberError: 'An error occurred adding a new member to team',
        addMemberSuccess: 'Member added to team!',
        delMemberNotSelected: 'Team or member not selected',
        delMemberSuccess: 'Member removed successfully!',
        delMemberTeamError: 'An error occurred deleting team member (team error)',
        delMemberRoles: 'Member not in org',
        delMemberNotExists: 'Member not in team',
        delMemberError: 'An error occurred removing member from the team',
        delMemberTitle: 'Do you want to remove this member from the team?',
        delMemberText: 'The user {email} will be removed from {team} team.',
        delMember: 'Remove Member',
        preDelError: 'An error occurred getting pre info',
        inviteNotAccepted: 'The user has to accept the invitation to join your organization before joining the team'
      },
      theme: {
        set: 'Set Theme',
        reset: 'Original Theme'
      },
      metrics: {
        title: "Metricas",
        newTitle: "Crear nueva métrica",
        create: "Crear",
        delTitle: "Eliminar métrica",
        create: "Borrar"
      },
      electronicSignatures: 'Electronic Signatures'
    },

    // createOrJoinOrg component
    createOrgs: {
      createTitle: 'Create a New Organization',
      createOrg: 'Create Organization',
      invitationsTitle: 'Organization Invites',
      orgName: 'Name',
      roles: 'Role(s)',
      actions: 'Actions',
      acceptDialogTitle: 'Do you want to accept this invite?',
      acceptDialogText: 'You will join {name} organization',
      createDialogTitle: 'Do you want to create this organization?',
      createDialogText: 'By creating an organization, you will gain the owner role over it. You are responsible for the correct use according to our Terms and Conditions. Incorrect use will result in the disabling of your account and your organization.',
      create: 'Create',
      formError: 'Check form for errors',
      createSuccess: 'Organization created successfully!',
      createFail: 'Error creating organization',
      reqError: 'Error making the request',
      inviteError: 'An error occurred accepting the invite'
    },
    // manageTemplates view
    manageTemplates: {
      uploadTitle: 'Upload Template',
      uploadedTitle: 'Uploaded Templates',
      uploadFile: 'Upload File',
      fileInput: 'Select File',
      uploadDialogTitle: 'Do you want to upload file?',
      uploadDialogText: 'Creators will be able to start processes using this template.',
      upload: 'Upload',
      deleteDialogTitle: 'Do you want to delete this template?',
      deleteDialogText: 'The template {name} will be deleted. Already started processes using it will still be available.',
      delete: 'Delete',
      fileName: 'Select a file to upload',
      uploadSuccess: 'File uploaded successfully!',
      uploadError: 'Error uploading file, please check template for errors',
      deleteError: 'Error deleting the template',
      deleteSuccess: 'Template deleted successfully!',
      copyDialogTitle: 'Select a template to copy its configuration from',
      copyDialogText: 'This will copy the configuration of similar tags, similar groups, description, and directory from the template you choose below:',
      copy: 'Copy',
      copySuccess: 'Template settings copied!',
      copyError: 'An error occurred copying the settings!'
    },
    // templateSettings view
    templateSettings: {
      title: 'Settings for {tName}',
      description: 'Short description',
      setDescription: 'Set Description',
      directory: 'Template directory',
      setDirectory: 'Set Directory',
      groupsAndOrder: 'Group Labels and Order',
      setGroupsAndOrder: 'Set Labels and Order',
      dropdown: 'Dropdown fields',
      setDropdown: 'Set Dropdown fields',
      noDropdownTags: 'This template does not contain dropdown fields',
      placeholders: 'Placeholders',
      placeholdersTitle: 'Manage Placeholder Names',
      setPlaceholders: 'Set new placeholder names',
      hints: 'Hints',
      hintsTitle: 'Manage Hints',
      setHints: 'Set new hints',
      descriptionDialogTitle: 'Do you want to set new description?',
      descriptionDialogText: 'Descriptions should be self-explanatory.',
      groupsDialogTitle: 'Do you want to set new group names and order?',
      groupsDialogText: 'Make sure the group names are easy to understand.',
      dropdownDialogTitle: 'Do you want to set new dropdown fields?',
      dropdownDialogText: 'Fields should be written exactly the way to be replaced on the template',
      tagsDialogTitle: 'Do you want to set new placeholder names?',
      tagsDialogText: 'Make sure the tag names are easy to understand.',
      hintsDialogTitle: 'Do you want to set new hints?',
      hintsDialogText: 'Hints should be short and easy to understand.',
      directoryDialogTitle: 'Do you want to set the directory?',
      directoryDialogText: 'This is useful for the directory view.',
      tagsError: 'An error occurred setting new placeholders',
      tagsSuccess: 'Tags set successfully!',
      hintsSuccess: 'Hints set successfully!',
      hintsError: 'An error occurred setting hints',
      groupsSuccess: 'Groups set successfully!',
      groupsError: 'An error occurred setting groups',
      dropdownSuccess: 'Dropdown fields set successfully',
      dropdownError: 'An error occurred setting dropdown fields',
      descriptionSuccess: 'Description set successfully!',
      descriptionError: 'An error occurred setting description',
      directorySuccess: 'Directory set successfully!',
      directoryError: 'An error occurred setting the directory',
      ungroupedFields: 'Ungrouped Fields',
      generatedFields: 'Auto-Generated Fields',
      setGeneratedFields: 'Set fields',
      noGeneratedFields: 'This template does not contain Auto-Generated Fields',
      dateTags: 'Date',
      currencyTags: 'Currency',
      numTags: 'Number',
      codeTags: 'Code',
      upperCase: 'Uppercase',
      femenine: 'Femenine',
      generatedFieldsSub: 'Check to generate fields configuration',
      generatedDialogTitle: 'Do you want to save generated fields settings?',
      generatedDialogText: 'The checked fields will be generated with uppercase and/or femenine(spansih) characters',
      setGenerated: 'Save Settings',
      generatedSuccess: 'Successfully saved settings',
      generatedError: 'An error occurred saving the settings',
      createOptionGroupDialogTitle: 'Create new option group',
      createOptionGroupDialogText: 'Set a group name, optionally add a hint, and select the options you want to group together in select input.',
      createGroup: 'Create Group',
      createGroupSuccess: 'Option group created',
      createGroupError: 'Option group creation failed',
      editOptionGroup: 'Save Group',
      editOptionGroupDialogTitle: 'Modify the option group',
      editOptionGroupSuccess: 'Option group modified',
      editOptionGroupError: 'Option group edit failed',
      deleteOptionGroupDialogTitle: 'Delete option group?',
      deleteOptionGroupDialogText: 'Option group `{name}` will be deleted',
      deleteOptionGroup: 'Delete',
      deleteOptionGroupSuccess: 'Option group deleted',
      deleteOptionGroupError: 'Option group deletion failed',
      optionGroupsSuccess: 'Option groups set successfully',
      optionGroupsError: 'An error occurred saving the option groups',
      setOptionGroups: 'Set option groups',
      optionGroupsDialogTitle: 'Do you want to set option groups?',
      optionGroupsDialogText: 'Option groups will be set and become available for new processes',
      noOptions: 'This template does not contain option fields to group together',
      publicSharing: 'Public Sharing',
      activated: 'Activated',
      deactivated: 'Deactivated',
      groups2share: 'Groups to share',
      getPublicLink: 'Get Link',
      optionGrouping: 'Option Grouping',
      optionGroupingDes: 'Group options together in a select',
      general: 'General',
      placeholdersAndHints: 'Placeholders and Hints',
      onlyMonth: 'Only Month',
      attachments: 'File Attachments',
      upgradePublicForms: 'Upgrade your subscription plan to share forms with a link',
      upgradeFileAttachments: 'Upgrade your subscription plan to attach file to processes',
      addAttachment: 'Add a file attachment',
      deleteAttachment: 'Delete file attachment',
      duplicatedAttachment: 'The name for the file attachment already exists',
      autoApprove: 'Approve process automatically',
      autoApproveCincel: 'Start the signature process automatically with CINCEL',
      autoApproveCincelSelect: 'Select the persons to sign the document',
      notifications: {
        title: 'Status update notifications',
        assigned: 'Process has been assigned',
        approved: 'Process has been approved',
        invalidEmail: 'Needs to be a valid email address',  
        set: 'Set notifications',
        placeHolders: 'Fields to share',
        emailsLabel: 'Emails to notify'
      },
      attachments: {
        title: 'File Attachments',
        appendToDocument: 'Append to Document',
        public: 'Public',
        publicRequired: 'Required on public form',
      }
    },
    projectSettings: {
      name: 'Name',
      addedOn: 'Added on',
      metrics: 'Metrics',
      title: 'Settings for project {name}',
      metricDialogTitle: 'Add metric to project',
      addMetric: 'Add',
      addMetricSuccess: 'Successfully added new metric!',
      addMetricError: 'An error occurred adding the new metric',
      deleteMetricDialogTitle: 'Do you want to delete this metric?',
      deleteMetricDialogText: 'The metric {name} will be deleted.',
      delete: 'Delete',
      deleteMetricSuccess: 'Successfully removed metric',
      deleteMetricError: 'An error occured removing the metric',
      editMetricDialogTitle: 'Edit metric {name}',
      editMetric: 'Update',
      editMetricSuccess: 'Successfully updated metric',
      editMetricError: 'An error occurred updating the metric'
    },
    // dashboard view
    dashboard: {
      title: 'Dashboard',
      generatedDocuments: 'Generated Documents',
      approvedProcesses: 'Approved Processes',
      activeProcesses: 'Active Processes',
      activeProcessesStatus: 'Active Processes\' status',
      approvalsTemplate: 'Approvals by template',
      approvedProcessesUser: 'Approved Processes by User',
      activeProcessesUserStatusCreate: 'Active Processes detailed',
      selectProjects: 'Filter by projects',
      notFound: 'Not found',
      userNotFound: 'No longer in org',
      createdSince: 'Days ago',
      selectUsers: 'Filter by users',
      dateRanges: {
        'Today': 'Today',
        'Yesterday': 'Yesterday',
        'This week': 'This week',
        'This month': 'This month',
        'This quarter': 'This quarter',
        'This year': 'This year',
        'Last 7 days': 'Last 7 days',
        'Last 30 days': 'Last 30 days',
        'Last week': 'Last week',
        'Last month': 'Last month',
        'Last quarter': 'Last quarter',
        'Last year': 'Last year'
      },
      timeGranularity: {
        'hour': 'Hour',
        'day': 'Day',
        'week': 'Week',
        'month': 'Month',
        'year': 'Year'
      },
      status: {
        'drafting': 'Drafting',
        'rejected': 'Rejected',
        'reviewing': 'Reviewing'
      },
      statusSingle: {
        'drafting': 'Drafting',
        'rejected': 'Rejected',
        'reviewing': 'Reviewing'
      }
    },
    // payments view
    payments: {
      title: 'Payments',
      currentSub: 'Current subscription includes:',
      howMuch: 'How much you will be charged for:',
      users: 'User | Users',
      amount: '${amount}/month',
      start: 'Start Subscription',
      update: 'Update Subscription',
      updating: 'Updating your subscription!',
      cancel: 'Cancel Subscription',
      moreUsers: 'Need more users?',
      contactSales: 'Contact sales and we will find a good fit for you',
      contact: 'Contact Sales',
      upgradeDialogTitle: 'Do you want to update your subscription?',
      upgradeDialogText1: 'The card ending with {lastDigits} will be charged a prorated amount corresponding to {pUsers}',
      upgradeDialogText2: 'and the time remaining for the next billing date. Keep in mind, you will be charged for {nUsers} users from the upcoming billing date.',
      downgradeDialogText: 'You are reducing the amount of users in your subscription. From the upcoming billing date to the future, you will be charged for {nUsers}.',
      downGradeLimit: 'You currently have more active users in your organizations than the ones selected to update your subscription to. Please remove {nUsers} users from your organization in the',
      organizationPage:'organization page.',
      confirmIns: 'Please write "UPDATE" to continue:',
      confirmWord: 'UPDATE',
      cancelDialogTitle: 'Do you want to cancel your subscription?',
      cancelIns: 'Please write "CANCEL" to continue:',
      cancelWord: 'CANCEL',
      cancelSubscription: 'Cancel Subscription',
      updateSuccess: 'Update successful! Your info will update in a few seconds',
      updateError: 'Update failed, please try again in an hour from now. Contact support of problem persists.',
      disableSuccess: 'Successfully cancelled your subscription!',
      disableError: 'Cancellation failed',
      recurrente: {
        cancelIns: 'You can cancel your subscription with our payments partner at: ',
        updatePlanIns: 'If you want to update your plan please contact support at: ',
        upgradeDialogTitle: 'Do you want to update your subscription?',
      }
    },
    pricingM: {
      current: 'Current',
      orderSupport: 'To easily manage your order, subscription, invoice and payment details, go to our payments partner page:',
      orderSupportLink: 'https://secure.2co.com/myaccount/',
      currentPlan: 'Current Plan',
      updatePlan: 'Update Plan',
      updatePlanSub: 'Update to {plan} Subscription plan',
      updatePlanText: 'You are about to change your current subscription plan to the {plan} plan.',
      updateDialogText1: 'The card ending with {lastDigits} will be charged a prorated amount corresponding to the new plan and the amount of users selected, taking in consideration the time remaining for the next billing date. Keep in mind, you will be charged fully from the upcoming billing date.',
      updateDialogText2: 'The card ending with {lastDigits} may be charged a prorated amount corresponding to the new plan and the amount of users selected, taking in consideration the time remaining for the next billing date. Keep in mind, you will be charged fully from the upcoming billing date.',
      activeUserLimit: 'You currently have more active users in your organizations than the ones selected to update your subscription to. Please remove {nUsers} users from your organization in the',
      updateToOtherPlan: 'Update plan subscription',
      updateCurrentPlan: 'Update Current Plan',
      updateCurrentPlanText: 'You are about to change the amount of users in your subscription plan to a total of {users}',
      choose: 'Three options, pick one.',
      noSize: 'No matter your needs, we got your back.',
      monthly: 'Monthly',
      annual: 'Annual',
      priceUser: '/ user per month',
      discount: 'Save 20% with annual payments',
      bulkDiscount: 'Save an additional 10% when acquiring more than 5 user subscriptions',
      $: '$',
      priceUserYear: ' / user per year',
      priceTotalMonth: 'total per month',
      priceTotalYear: 'total per year',
      minimum: 'Includes {n} paid users',
      additionalUsers: '{price} extra per additional user',
      functions: 'Functions',
      comparison: 'Plan Comparison',
      coming: 'Coming soon',
      functionalities: [
        'Access to our templates',
        'Unlimited processes',
        'Unlimited personalized templates',
        'Standard support',
        'Dedicated support',
        'Review Process',
        'Word Add-in',
        'Dashboard',
        'Alert System',
        'Batch Generation',
        'Form sharing',
        'Electronic Signature',
        'Query RGP-GT',
        'Planifier',
        'Custom Workflows',
        'AI Template Automation'
      ],
      functionalitiesDescribed: {
        'Access to our templates': 'Use any of the ready to use templates provided by us.',
        'Unlimited processes': 'Create as many processes as you need to generate documents.',
        'Unlimited personalized templates': 'You can upload as many personalized templates as you need, available only to your organization.',
        '10 processes per Month': 'Approve up to 10 process each month for document generation',
        'Standard support': 'Chat and email our support team',
        'Dedicated support': 'Direct support channel, any day, any time.',
        'Review Process': 'Before generating a document, send it to a review process to greatly reduce errors.',
        // eslint-disable-next-line
        'Dashboard': 'Get to know what your team is working on and plan ahead.',
        'Alert System': 'Create alerts to remind you for specific deadlines and bring better support to your clients.',
        'Batch Generation': 'Generate a batch of documents from an Excel File',
        'Form sharing': 'Pass on the work and get the information you need directly from your clients, easy and secure.',
        'Word Add-in': 'Easily create personalized templates with the help of our Word Add-In, available at the office store.',
        'Electronic Signature': 'Complete your legal processes with integrated electronic signatures.',
        'Query RGP-GT': 'Automate your queries to the General Property Registry of Guatemala',
        'Planifier': 'Create Aziumuts for multiple plans from a single Excel file.',
        'Custom Workflows': 'Enforce steps and requirements to approve a process.',
        'AI Template Automation': 'Makes personalized templates easier, by scanning your documents with entity recognition AI algorithms.'
      },
      planDict: {
        basic: 'Basic',
        standard: 'Standard',
        advanced: 'Advanced'
      },
      plans: [
        {
          title: 'Basic',
          key: 'basic',
          price: ['150', '1440'],
          priceUserYear: ['300', '240'],
          priceUser: ['25', '20'],
          minUsers: '5',
          details: {
            'Access to our templates': true,
            'Unlimited processes': true,
            'Unlimited personalized templates': true,
            'Standard support': true,
            'Word Add-in': true,
            'Review Process': false,
            // eslint-disable-next-line
            'Dashboard': false,
            'Alert System': false,
            'Batch Generation': false,
            'Form sharing': false,
            'Electronic Signature': true,
            'Query RGP-GT': false,
            'Planifier': false,
          }
        },
        {
          title: 'Standard',
          key: 'standard',
          price: ['250', '4320'],
          priceUserYear: ['420', '336'],
          priceUser: ['35', '28'],
          minUsers: '10',
          details: {
            'Access to our templates': true,
            'Unlimited processes': true,
            'Unlimited personalized templates': true,
            'Standard support': true,
            'Word Add-in': true,
            'Electronic Signature': true,
            'Query RGP-GT': true,
            'Planifier': true,
            'Review Process': true,
            // eslint-disable-next-line
            'Dashboard': true,
            'Alert System': true,
            'Batch Generation': false,
            'Form sharing': false,
            // eslint-disable-next-line
            'Custom Workflows': 'inProgress',
            'AI Template Automation': 'inProgress'
          }
        },
        {
          title: 'Advanced',
          key: 'advanced',
          price: ['450', '4430'],
          priceUserYear: ['540', '432'],
          priceUser: ['45', '36'],
          minUsers: '20',
          details: {
            'Access to our templates': true,
            'Unlimited processes': true,
            'Unlimited personalized templates': true,
            'Dedicated support': true,
            'Word Add-in': true,
            'Electronic Signature': true,
            'Query RGP-GT': true,
            'Planifier': true,
            'Review Process': true,
            // eslint-disable-next-line
            'Dashboard': true,
            'Alert System': true,
            'Batch Generation': true,
            'Form sharing': true,
            // eslint-disable-next-line
            'Custom Workflows': 'inProgress',
            'AI Template Automation': 'inProgress'
          }
        }
      ],
      // template customization
      templateCustomization: 'Template Customization',
      customizationSlogan: 'Don\'t want to create templates by yourself?',
      customizationSlogan2: 'We got you covered.',
      customizationDes: 'We offer a template customization service just for you. We take responsibility for meeting your requirements and ensuring quality for your custom templates. The good thing is we create it once, and you can use the same template multiple times.',
      customizationPrice: '99',
      customizationPriceDes: 'per template',
      customizationSteps: ['Base document identification', 'Use cases definitions', 'Field definitions', 'Two iterations for refining details', 'Template delivery'],

      // single User
      singleTitle: 'Need a single user?',
      singlePrice: '45',
      perMonth: 'per Month',
      singleFeatures: {
        'Access to our templates': true,
        'Unlimited processes': true,
        'Unlimited personalized templates': true,
        'Standard support': true,
        // eslint-disable-next-line
        'Word Add-in': true,
      },
      signUp: 'Sign Up',

      // freeAccount
      callToAction: 'Verify your phone to use your free account!',
      freePlan: {
        title: 'Free Plan',
        key: 'free',
        price: ['0', '0'],
        priceUserYear: ['0', '0'],
        priceUser: ['0', '0'],
        minUsers: '1',
        details: {
          'Access to our templates': true,
          '10 processes per Month': true,
          'Standard support': true,
        }
      },
      startFreePlan: 'Start free plan',
      setFreeAccountError: 'An error occurred, please contact support',
      confirmCodeErrors: {
        alreadyInUse: 'The phone number is already in use'
      },
      confirmCodeSuccess: 'Your phone number is verified',
      translationsPhone:{
        countrySelectorLabel: 'Country code',
        countrySelectorError: 'Choose a country',
        phoneNumberLabel: 'Phone number',
        example: 'Example:'
      }
    },
    // myAccount view
    myAccount: {
      title: 'My Account',
      account: 'Account',
      deactivate: 'Deactivate',
      accountDetails: 'Account details',
      resetPassword: 'Reset password',
      resetPasswordIns: 'Click below if you want to send a password reset email',
      resetPasswordBtn: 'Send passowrd reset email',
      mfa: 'Multi-factor Authentication',
      mfaIns: 'Increase the security for your user account with Multi-factor authentication. You will need to enter a code sent to a phone number in order to login.',
      mfaIns2: 'Select the country code and your phone number. You will receive a confirmation code through SMS.',
      mfaIns3: 'Type in the confirmation code you receive.',
      mfa: 'Multi-factor Authentication',
      startMFA: 'Start',
      enrollMFA: 'Enroll MFA',
      unenrollMFA: 'Unenroll MFA',
      deactivateTitle: 'Deactivate your account',
      deactivateIns: 'Click below if you want to deactivate your account',
      deactivateBtn: 'Deactivate Account',
      organizationTitle: 'Leave your organization',
      organizationIns: 'Click below if you want to leave your current organization',
      leaveOrg: 'Leave organization',
      organizationInsOwner: 'In order to leave your oganization, you need to transfer the ownership to another account that is already part of your organization. Please type the email address of the account you want to transfer the rights to:',
      emailTransfer: 'Email to transfer ownership to',
      leaveOrgTitle: 'Do you want to leave this organization?',
      leaveOrgText: 'You will loose access to the organization\'s resources. After, you will be able to either accept invites from another oganization or create one.',
      leaveOrgConfirm: 'Please write your current organization name: {orgName}'
    },
    thankYou: {
      title: 'Thank you for trusting us!',
      msg: 'It may take a few seconds to see the changes reflected on your organization',
      msg2: 'In some cases, payment may be delayed for around 10 minutes.',
      redirect: 'You will be redirected to the home menu in 5 seconds.'
    },
    thankYouSign: {
      title: 'Check your email',
      msg: 'Congratulations for creating your account! This is your first step towards simpler and more productive processes.',
      msg2: 'Please check your email to verify your email address.',
    },
    emailVerify: {
      title: 'Your email is verified!',
      btn: 'Go to login page',
      expired: 'This link is expired, please request another email verification.'
    },
    passwordReset: {
      title: 'Set new password',
      newPass: 'New Password',
      setPass: 'Set password',
      expired: 'This link is expired, please request another password change.',
      btn: 'Go to login page'
    },
    MFAunenroll: {
      title: 'The Multi-factor authentication has been unenrolled',
      btn: 'Go to login page',
      expired: 'This link is expired, log in to unenroll the Multi-factor authentication.'
    },
    incompleteUser: {
      title: 'Finish user setup',
      text: 'Please complete your info to use your account',
      setUp: 'Set Up User',
      needPassword: 'We need you to set up a password for your account. An email reset password will be sent to you.',
      passResetEmailSent: 'Password reset email was sent successfully, please check your inbox',
      passResetEmailError: 'There was an error sending your email, contact support if problem persists.',
      success: 'Info updated successfully',
      error: 'If problem persists, contact support'
    },
    rgp:{
      title: 'General Property Registry of Guatemala (BETA)',
      description: 'Start an automated query and receive the files in your e-mail.',
      user: 'User',
      userExample: 'user@example.com',
      pass: 'Password', 
      finca: 'Finca',
      folio: 'Folio', 
      libro: 'Libro',
      departamento: 'Departamento',
      startQuery: 'Start Query',
      confirmation: 'Confirmación',
      msg1: 'One credit from your account will be used. A .zip file will be created with the downloaded documents and images generated from the process. This operation may last several minutes, depending on the complexity of the results.',
      msg2: 'Do you wish to start an automated query at the General Property Registry of Guatemala?',
      historyTitle: '30 day history',
      getAll: 'Get all support documents',
      unstableRGP: 'The platform for the RGP from Guatemala is unstable. We are constantaly working towards getting the service back to normal. The documents process are not being downloaded. Supporting documents with many pages may initiate a "Download Request" (new feature from RGP). Additionally, any other document may fail to download.'
    },
    batchGenerate: {
      instructions: 'Select a Excel file with extension xlsx and click on generate',
      getTemplate: 'Click here for a template!',
      generate: 'Batch Generate',
      send2unnasigned: 'Create unassigned processes'
    },
    docuSign: {
      signIn: 'Sign in',
      unlink: 'Unlink Account'
    },
    cincel: {
      instructions: 'Please enter your API Key:',
      currentAPI: 'Your current API Key:',
      apiKey: 'API Key',
      link: 'Link Account',
      unlink: 'Unlink Account',
      getApiKey: 'Get a new API Key',
      sign: 'Sign with Cincel',
      process: 'Signature Process with Cincel',
      signed: 'Signed',
      unsigned: 'Unsigned',
      stage: 'Stage',
      accepted: 'Accepted',
      signedFile: 'Document signed',
      evidenceFile: 'Evidence document',
      start: 'Start a new signature process',
      invitations: 'Invitations',
      startProcess: 'Start Process',
      minInvite: 'You need to add at least one invitation to sign',
      success: 'Process Started',
      error: 'An error just happened',
      idVerification: 'ID Verification',
      repeatedEmails: 'Repeated emails'
    },
    shared: {
      sharedProcesses: 'Shared Forms Processes',
      shareDialogTitle: 'Do you want to send your answers?',
      shareDialogText: 'The filled information will be sent to {orgName} and its members.',
      sendAnswers: 'Send Answers'
    },
    planifier: {
      title: 'Planifier-GT',
      description: 'Instantly create azimuts for multiple plans',
      instructions: 'Upload an Excel file with no headers, horizontally write the following information in this order:',
      info: ['From', 'To', 'Minutes', 'Degrees', 'Seconds', 'Distance', 'Adjoining'],
      
      results: 'Results',
      from: 'From',
      to: 'To',
      minutes: 'Minutes',
      degrees: 'Degrees',
      seconds: 'Seconds',
      distance: 'Distance',
      adjoining: 'Adjoining',
      generate: 'Generate Text',
      points: [
        {'from': '0', 'to': '1', 'degrees': '80', 'minutes': '19', 'seconds': '48', 'distance': '174.50', 'adjoining': 'lote cuatro'},
        {'from': '1', 'to': '2', 'degrees': '338', 'minutes': '33', 'seconds': '7', 'distance': '6.15', 'adjoining': 'finca el cerro'}, 
        {'from': '2', 'to': '3', 'degrees': '260', 'minutes': '19', 'seconds': '48', 'distance': '174.22', 'adjoining': 'lote tres'}, 
        {'from': '3', 'to': '0', 'degrees': '162', 'minutes': '26', 'seconds': '57', 'distance': '6.05', 'adjoining': 'calle Real'}, 
        null, 
        {'from': '0', 'to': '1', 'minutes': '90', 'degrees': '1', 'seconds': '30', 'distance': '20.5', 'adjoining': 'casa cinco'}, 
        {'from': '1', 'to': '2', 'minutes': '350', 'degrees': '45', 'seconds': '20', 'distance': '10', 'adjoining': 'casa tres'}, 
        {'from': '2', 'to': '3', 'minutes': '270', 'degrees': '90', 'seconds': '22', 'distance': '15', 'adjoining': 'boulevar principal'}, 
      ],
      format: 'Format',
      normal: 'Normal',
      alt1: 'Alt-1',
      style: 'Style',
      currencyType: 'Currency type',
      distanceSymbol: 'Distance symbol',
      areaSymbol: 'Area symbol',
      symbols: 'With Symbols',
      USD: 'Dólares',
      GTQ: 'Quetzales',
      includeLetters: 'Numerate sections with letters',
      convertColindancia: 'Convert codes and numbers from the adjoinings to letters automatically (BETA)',
      upperCaseColidancia: 'Upper case the adjoinings',
      convertTitle: 'Convert codes and numbers from the titles to letters automatically (BETA)',
      upperCaseTitle: 'Upper case the title',
      titleInstructions: 'If you want to add a title to each plan, you can do so by adding the following items on the first row of a plan in this order: title*, value, total squared meters*, and real squared meters. The items with * are required if you use this option.',
      downloadTemplate: 'Example Template',
      error: 'An error occured check for file formatting',

    },
    alerts: {
      title: 'Alerts',
      description: 'Manage alerts and reminders',
      alertDate: 'Alert Date',
      create: 'Create an Alert',
      upcoming: 'Upcoming Alerts',
      past: 'Past Alerts',
      dateMissing: 'Pick a date',
      checkFields: 'Check the fields',
      process: 'Associated process',
      client: 'Associated client',
      project: 'Associated project',
      createDate: 'Creation Date',
      message: 'Message',
      alertEmails: 'Emails',
      updated: 'Updated alert',
      updatedError: 'Error updating the alert',
      completedBy: 'Marked completed by: {user}'
    },
    freeTrial: {
      CTA: '15 days FREE',
      CTA2: 'No credit cards, no hidden costs, no compromises.',
      instructions: 'Fill in the info, invite your colleagues, and explore your potential!',
      userInfo: 'Personal Info',
      orgInfo: 'Organization Info',
      start: 'Start free trial'
    },
    reports: {
      title: 'Reports',
      fromDate: 'From date',
      toDate: 'To date',
      generate: 'Generate Report',
      status: 'Status',
      statuses: [
        // {value: 'drafting', text:'Drafting'},
        // {value: 'rejected', text: 'Rejected'},
        // {value: 'reviewing', text: 'Reviewing'},
        {value: 'approved', text: 'Approved'}
      ],
      processes: {
        success: 'Report generated',
        error: 'An error occurred generating the report',
        invalidRange: 'Invalid date range',
        fromHigher: 'From date is larger than the To date',
        nullStatuses: 'Please select at least one status',
        noProcesses: 'No processes found'
      }
    },
  },
  es: {
    customizable: 'Puedes escribir uno nuevo aquí',
    initiatedBy: 'Iniciado por',
    consultant: 'Consultor',
    publicForm: 'Formulario Público',
    upload: 'Cargar archivos',
    internal: 'Internos',
    public: 'Públicos',
    add: 'Agregar',
    security: 'Seguridad',
    start: 'Empezar',
    success: 'Operación exitosa',
    error: 'Ocurrió un error',
    next: 'Siguiente',
    back: 'Atras',
    completedDate: 'Fecha de envío',
    assignmentDate: 'Fecha de asignación',
    download: 'Descargar',
    client: 'Cliente',
    emailExample: 'ejemplo@empresa.com',
    pending: 'Pendiente',
    done: 'Completado',
    daysLeft: 'Días restantes',
    open: 'Abrir',
    continue: 'Continuar',
    query: 'Consulta',
    time: 'Hora',
    from: 'De',
    worksAt: 'Labora en',
    groups: 'Grupos',
    utilities: 'Herramientas',
    utilitiesDes: 'Herramientas adicionales',
    sendCode: 'Enviar Código',
    enterCodeHere: 'Ingresa tu código aquí',
    verificationCodeSent: 'Se envío un SMS a tu celular con el código de verificación.',
    update: 'Actualizar',
    themeChange: 'Cambiar color',
    team: 'Equipo',
    noData: 'No hay datos disponibles',
    selectTeam: 'Selecciona un Equipo de Trabajo',
    selectTeamHint: 'Esto permitirá acceso al proceso para el equipo seleccionado',
    plans: 'Planes de Suscripción',
    upgradeSubPlan: 'Mejora tu plan de Suscripción',
    pricing: 'Precios',
    generateBatch: 'Generación en grupo',
    upgrade: 'Plan +',
    waiting: 'En espera',
    sent: 'Enviado',
    completed: 'Completado',
    date: 'Fecha',
    message: 'Mensaje',
    edit: 'Editar',
    copy: 'Copiar',
    delete: 'Borrar',
    deleted: 'Eliminado',
    optionalGroups: 'Grupos Opcionales',
    fields: 'Campos',
    multiple: 'Multiple',
    required: 'Requerido',
    groupName: 'Nombre del grupo',
    hint: 'Pista',
    options: 'Opciones',
    status: 'Estado',
    project: 'Proyecto',
    template: 'Plantilla',
    total: 'Total',
    yes: 'Sí',
    no: 'No',
    email: 'Correo',
    emailConf: 'Confirmación de correo',
    password: 'Contraseña',
    enterPassword: 'Ingresa tu contraseña',
    fPass: '¿Olvidaste tu contraseña?',
    fPass2: '¿Olvidaste tu contraseña?',
    or: 'ó',
    goToDocs: 'Ir a Docs',
    login: 'Inicia Sesión',
    signUp: 'Crea tu cuenta',
    firstName: 'Nombre',
    lastName: 'Apellido',
    disabled: 'Deshabilitado',
    close: 'cerrar',
    search: 'Buscar',
    title: 'Título',
    name: 'Nombre',
    description: 'Descripción',
    timeCreated: 'Fecha de Creación',
    logOut: 'Cerrar Sesión',
    actions: 'Acciones',
    country: 'País',
    orgName: 'Nombre de la organización',
    address: 'Dirección',
    phoneNumber: 'Número de teléfono',
    deactivate: 'Desactivar',
    directory: 'Directorio',
    congratulations: 'Felicidades!',
    ok: 'Ok',
    cancel: 'Cancelar',
    save: 'Guardar',
    accept: 'Aceptar',

    // homeDash
    home: 'Inicio',
    processes: 'Procesos',
    processesDes: 'Administra los procesos',
    review: 'Revisar',
    reviewsDes: 'Revisa los procesos',
    organization: 'Organización',
    organizationDes: 'Administra tu organización y los roles',
    templates: 'Plantillas',
    templatesDes: 'Administra tus plantillas',
    paymentsT: 'Planes de Suscripción',
    paymentsDes: 'Pagos y tu suscripción',
    dashboardT: 'Tablero',
    dashboardDes: 'Monitorea la productividad',
    createOrg: 'Crea o únete a una organización',
    createOrgDes: 'Crea una nueva organización ó acepta una invitación que te hayan mandado',
    emailVer: 'Por favor verifique su correo',
    emailVerDes: 'Revise su buzón para verificar su correo electrónico. Despues inicie sesión de nuevo. Si no encuentra el correo de verificación, puedes hacer click para enviar otro.',
    sendEmail: 'Enviar correo',
    account: 'Cuenta',
    emailSent: 'El correo está en camino, revisa tu buzón!',
    emailSentError: 'Ocurrió un error al mandar el correo de verificación',
    attentionRequired: 'Tu atención es requerida para activar tu organización',
    attentionRequired2: 'Por favor habla con el administrador de tu organización para resolver temas de pagos',
    goToPayments: 'Ir a Pagos',
    // contract view
    startProcess: 'Inicia un proceso',
    yourDrafts: 'Tus borradores',
    reviseRejected: 'Revisa rechazados',
    generateApproved: 'Genera aprobados',

    // login view
    recoverMsg: 'Envíaremos un enlace de recuperación a:',
    sendRecover: 'Enviar enlace',
    loginView: {
      emailRules: [
        v => !!v || 'Correo electrónico requerido',
        v => /.+@.+\..+/.test(v) || 'El correo electrónico debe ser válido',
      ],
      passwordRules: [
        v => !!v || 'Contraseña requerida',
      ],
      loginErr: 'Credenciales Inválidas',
      recoverSuc: 'Correo de recuperación enviado, revisa tu buzón',
      recoverErr: 'Ocurrió un error al mandar correo de recuperación',
      userDisabled: 'Usuario deshabilitado, contáctanos',
      invalidEmail: 'Revisa el correo electrónico',
      networkFailed: 'Revisa tu conexión a internet',
      MFAins: 'Se enviará un código de verificación al siguiente número de teléfono: {phone}',
      sendCode: 'Enviar código',
    },
    signupView: {
      redoCaptcha: 'Por favor completa el captcha',
      fillFields: 'Por favor llena los campos correctamente',
      emailExists: 'El correo electrónico ya existe en la plataforma, inicia sesión',
      invalidCaptcha: 'Capcha invalido, por favor llena la casilla otra vez',
      createFail: 'Algo salió mal, recarga la página',
      unkownErr: 'Error desconocido, recarga la página',
      success: 'Este es el primer paso para ser más productivo. Ahora revisa el buzón de tu correo electrónico para verificación de tu dirección y poder usar tu cuenta.',
      otherProviders: 'Otros proveedores'
    },

    rules: {
      fileAttachmentRulesRequired: [
        v => !!v || 'El archivo es requerido',
        v => !v || v.size < 5000000 || 'El archivo debe tener menos de 5Mb',
      ],
      fileAttachmentRules: [
        v => !v || v.size < 5000000 || 'El archivo debe tener menos de 5Mb',
      ],
      guidRules: [
        v => !!v || 'Ingresa tu API Key',
        // v => /^(((?=.*}$){)|((?!.*}$)))((?!.*-.*)|(?=(.*[-].*){4}))[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}?[}]?$/.test(v) || 'Example: 123e4567-h89b-12d3-a456-9AC7CBDCEE52',
      ],
      fullNameRules: [
        v => !!v || 'El nombre es requerido',
        v => (v && v.length <= 60) || 'Debe ser menos de 60 letras',
      ],
      verifyCodeRules: [
        v => !!v || 'Por favor ingresa tu código',
        v => (v && v.length == 6) || 'Ingresa 6 dígitos',
        v => /[0-9]{6}/.test(v) || 'Utiliza solamente dígitos',
      ],
      singleNumberRules: [
        v => !!v || 'Porfavor ingresa un número válido',
      ],
      firstNameRules: [
        v => !!v || 'Nombre requerido',
        v => (v && v.length <= 25) || 'El nobmre debe tener menos de 25 caracteres',
      ],
      lastNameRules: [
        v => !!v || 'Apellido requerido',
        v => (v && v.length <= 25) || 'El apellido debe tener menos de 25 caracteres',
      ],
      emailRules: [
        v => !!v || 'Correo electrónico requerido',
        v => /.+@.+\..+/.test(v) || 'El correo electrónico debe ser válido',
      ],
      passwordRules: [
        v => !!v || 'Contraseña requerida',
      ],
      passRules: [
        v => (v && v.length >= 8) || 'La contraseña debe ser de al menos 8 caracteres',
        v => /(?=.*[A-Z])/.test(v) || 'Debe tener al menos una letra mayúscula',
        v => /(?=.*\d)/.test(v) || 'Debe contener algún número',
        v => /([ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(v) || 'Debe contener algún caracter especial, ej:[!@#$%_]'
      ],
      titleRules: [
        v => !!v || 'El título es requerido',
      ],
      fieldRules: [
        v => !!v || 'El campo es requerido',
      ],
      numberRules: [
        v => !!v || 'El campo es requerido',
        v =>  /^((\d{1,3}(,\d{3})*(\.[\d]+)?)|(^[\d]+)(\.[\d]+)?)( ((\d{1,3}(,\d{3})*)(\.[\d]+)?|([\d]+)(\.[\d]+)?))*$/g.test(v) || 'Los números pueden ser con comas y decimales, y separados entre sí con un espacio ej: 1,234.56 7890',
      ],
      codeRules: [
        v => !!v || 'El campo es requerido',
        // eslint-disable-next-line
        v =>  /^[a-zA-Z\d-_\/.áéíóúÁÉÍÓÚ]+(( [a-zA-Z\d-_\/.áéíóúÁÉÍÓÚ]+)?)*$/g.test(v) || 'Los códigos deben contener letras, números, "-", "_", "." y "/"',
      ],
      currencyRules: [
        v => !!v || 'El campo es requerido',
        v =>  /^((\d{1,3}(,\d{3})*)|[\d]+)(\.[0-9]{2})$/g.test(v) || 'Debe contener 2 decimales y las unidades pueden ser separadas con comas ej: 1500.21 ó 1,500.21',
      ],
      countryRules: [
        v => !!v || 'El país es requerido'
      ],
      phoneRules: [
        v => !!v || 'Número de teléfono requerido',
        v => (v && v.length == 8) || 'El número debe tener al menos 8 dígitos',
        v => /[0-9]{8}/g.test(v) || 'El número debe tener solo dígitos 0-9',
      ],
      inputRules: [
        value => !!value || 'Debes seleccionar un documento'
      ],
      descriptionRules: [
        v => !!v || 'Descripción es requerida',
        v => (v && v.length <= 50) || 'La descripción debe tener menos de 50 caracteres',
      ],
      directoryRules: [
        // v => !!v || 'Field is required',
        v => /^(|(\/\w+( \w+)*)+)$/.test(v) || 'Directorio inválido ej: /abc/123 ó dejar en blanco',
      ],
      optionGroupRules: [
        v => !!v || 'Campo es requerido',
        v => (v && v.length >= 2) || 'Selecciona al menos 2 opciones',
      ],
      optionGroupFieldRequired: [
        v => !!v || 'Campo es requerido',
      ],
      optionGroupFieldRM: [
        v => !!v || 'Campo es requerido',
        v => (v && v.length >= 1) || 'Selecciona al menos 1 opción',
      ],
      loopRules: [
        v => !!v || 'Campo es requerido',
        v => (v && v.length >= 1) || 'Agrega al menos 1 elemento',
      ]
    },
    // create component,
    create: {
      title: 'Crea un Proceso',
      tableTitle: 'Plantillas de {org}',
      tableView: 'Vista en Tabla',
      directoryView: 'Vista en Directorio',
      name: 'Nombre',
      description: 'Descripción',
      timeCreated: 'Fecha de Creación',
      newProcessTitle: 'Título del nuevo proceso',
      saveDraft: 'Guardar borrador',
      sendForReview: 'Mandar a revisión',
      sendDialogTitle: '¿Quieres crear un nuevo proceso?',
      sendDialogText: 'Revisa que todos los campos estén llenos correctamente. Esto comenzará un proceso de revisión para poder generar el documento.',
      startProcess: 'Crear Proceso',
      saveDialogTitle: '¿Quieres guardar un nuevo borrador?',
      saveDialogText: 'Podrás continuar trabajando más tarde.',
      saveProcess: 'Guardar Borrador',
      checkErrors: 'Por favor revisa el formulario por errores',
      fieldMissing: 'Ocurrió un error al generar campos especiales',
      attachmentMissing: 'Necesitas subir los documentos adjuntos requeridos',
      sendSuccess: '¡Se creó el proceso exitosamente!',
      sendFail: 'Ocurrió un error al crear un nuevo proceso',
      saveSuccess: '¡El borrador se guardó exitosamente!',
      saveFail: 'Ocurrió un error al guardar el borrador',
      tagProcess: 'Agrega etiquetas',
      assignProject: 'Asignar a proyecto',
      approveProcess: 'Aprobar proceso',
      numberFieldError: 'Revisa los campos de números',
      codeFieldError: 'Rivisa los campos de códigos',
      currencyFieldError: 'Revisa los campos de monedas',
      updateBatch: 'Cambia tu plan de suscripción para poder generar documentos en grupos',
      approveProcessTitle: '¿Quieres aprobar un nuevo proceso?',
      approveProcessText: 'Por favor revisa que los campos estén llenos correctamente. Después de aprobar no se podrán modificar los campos.',
      upgradePlan: 'Puedes reducir errores por medio de un proceso de revisión al mejorar el plan de tu suscripción.',
      upgradeDialogTitle: '¿Quieres abandonar esta página antes de guardar tu proceso?',
      upgradeDialogText: 'Se perderá la información ingresada para iniciar este proceso.',
      batchDialogTitle: 'Generación en Grupo',
      getPublicUrl: 'Compartir Link',
      triedToSendToReview: 'Esta plantilla requiere adjuntar almenos un documento al proceso. Guarda este proceso como borrador y luego agrega los documentos adjuntos.',
      triedToSendToReviewTitle: 'Plantilla requiere documentos adjuntos'
    },

    // drafts component
    drafts: {
      startRequest: 'Iniciar Solicitud',
      requestDialogTitle: '¿Quieres iniciar una solicitud para este proceso?',
      requestDialogText: '',
      title: 'Continúa en tus borradores',
      tableTitle: 'Borradores',
      sendForReview: '@:create.sendForReview',
      sendDialogTitle: '¿Quieres crear un nuevo proceso?',
      sendDialogText: 'Revisa que todos los campos estén llenos correctamente. Esto comenzará un proceso de revisión para poder generar el documento.',
      startProcess: 'Crear Proceso',
      saveDialogTitle: '¿Quieres guardar el borrador actual?',
      saveDialogText: 'Podrás continuar trabajando más tarde.',
      saveDraft: 'Guardar Borrador',
      deleteDialogTitle: '¿Queres eliminar tu borrador?',
      deleteDialogText: 'El borrador con título {title} será eliminado y no podrás recuperarlo.',
      deleteDraft: 'Eliminar',
      errorDecrypt: 'Error al interpretar datos',
      saveSuccess: '¡El borrador ha sido guardado exitosamente!',
      saveFail: 'Ocurrió un error guardando el borrador',
      checkErrors: 'Por favor revisa el formulario por errores',
      fieldMissing: 'Ocurrió un error al generar campos especiales',
      attachmentMissing: 'Necesitas subir los documentos adjuntos requeridos',
      sendSuccess: 'Process created successfully!',
      sendFail: 'Ocurrió un error al crear un proceso',
      deleteSuccess: '¡Se eliminó el borrador exitosamente!',
      deleteFail: 'Ocurrió un error al eliminar el borrador',
      deleteFailSelected: 'No hay borrador seleccionado',
      copyDialogTitle: 'Selecciona el borrador al cual copiar la información',
      copyDialogText: 'Esta acción copiará la información hacia el borrador que escojas abajo:',
      copy: 'Copiar',
      copySuccess: 'Información copiada',
      copyError: 'Ocurrió un error al copiar la información',
      updateShare: 'Cambia de plan para poder compartir formularios',
      updatePublic: 'Cambia de plan para iniciar procesos con links de formularios públicos',
      updateFileAttachments: 'Cambia de plan para agregar archivos adjuntos a los procesos',
      shared: 'Formularios Compartidos',
      createShared: 'Compartir un nuevo formulario',
      shareForm: 'Compartir formulario', 
      sharedDetailTitle: 'Formulario compartido con {name} al correo electrónico {email}',
      useAnswers: 'Utilizar respuestas',
      warningAttachments: 'Los archivos son de usuarios externos. Siempre escanea los archivos por viruses.',
      noAttachments: 'Esta proceso no está configurado con archivos adjuntos.'
    },
    unassigned: {
      short: 'Buzón',
      title: 'Procesos para asignar',
      tableTitle: 'Procesos',
      deleteDialogTitle: '¿Deseas eliminar este proceso?',
      deleteDialogText: 'El proceso no asignado con título {title} será eliminado',
      delete: 'Eliminar',
      assign: 'Asignar Proceso',
      assignDialogTitle: 'Asignar proceso a un usuario',
      assignDialogText: 'Selecciona un usuario de la organización al cual quieras asignarle este proceso',
      assignSuccess: 'Proceso asignado exitósamente',
      assignError: 'Ocurrió un error',
      clientInfo: 'Información del cliente'
    },
    // rejected component
    rejected: {
      title: 'Revisa tus procesos Rechazados',
      tableTitle: 'Por revisar',
      sendForReview: 'Mandar a revisión',
      reviewedOn: 'Fecha de Revisión',
      motive: 'Razón de rechazo:',
      sendDialogTitle: '¿Quieres enviar este proceso a revisión?',
      sendDialogText: 'Revisa que todos los campos estén llenos correctamente. Esto comenzará un proceso de revisión para poder generar el documento.',
      sendProcess: 'Enviar Proceso',
      deleteDialogTitle: '¿Quieres elminar este proceso?',
      deleteDialogText: 'El proceso con título {title} será eliminado y no podrás recuperarlo.',
      deleteRejected: 'Eliminar',
      errorDecrypt: 'Error al interpretar datos',
      checkErrors: 'Por favor revisa el formulario por errores',
      fieldMissing: 'Por favor revisa el formulario por campos vacíos',
      attachmentMissing: 'Necesitas subir los documentos adjuntos requeridos',
      sendSuccess: '¡El proceso ha sido enviado a revisión!',
      sendFail: 'Ocurrió un error al enviar el proceso',
      deleteSuccess: '¡El proceso ha sido eliminado exitosamente!',
      deleteFail: 'Ocurrió un error al eliminar el proceso',
      deleteFailSelected: 'No hay proceso seleccionado'
    },

    // approved component
    approved: {
      title: 'Genera un Documento',
      tableTitle: 'Procesos Aprobados',
      generate: 'Generar Documento',
      reviewedOn: '@:rejected.reviewedOn',
      generateDialogTitle: '¿Quieres generar este documento?',
      generateDialogText: 'El proceso con título {title} será generado y descargado a tu dispositivo',
      generateAction: 'Generar',
      errorDecrypt: 'Error al interpretar datos',
      generateSuccess: '¡El documento ha sido generado! Revisa tus descargas',
      generateFail: 'Ocurrió un error al generar el documento',
      copyDialogTitle: 'Selecciona el borrador al cual copiar la información',
      copyDialogText: 'Esta acción copiará la información del proceso aprovado hacia el borrador que escojas abajo:',
      copy: 'Copiar',
      copySuccess: 'Información copiada',
      copyError: 'Ocurrió un error al copiar la información',
      deleteSuccess: '¡El proceso ha sido eliminado exitosamente!',
      deleteFail: 'Ocurrió un error al eliminar el proceso',
      deleteFailSelected: 'No hay proceso seleccionado',
      deleteDialogTitle: '¿Quieres eliminar este proceso?',
      deleteDialogText: 'El proceso con título {title} será eliminado y no podrás recuperarlo.',
      delete: 'Eliminar',
      reviewer: 'Revisor',
      createAlert: 'Crear Alerta',
      createAlertTitle: 'Crear nueva alerta',
      alertError: 'Ocurrió un error al crear la alerta',
      alertSuccess: '¡Alerta creada exitosamente!',
      pressEnter: 'Presiona Enter para agregar el correo.',
      deleteAlertTitle: '¿Quieres eliminar esta alerta?',
      deleteAlertText: 'La alerta con nombre {name} será eliminada.',
      deleteAlert: 'Borrar Alerta',
      deleteAlertSuccess: 'Alerta eliminada exitosamente',
      deleteAlertError: 'Ocurrió un error al eliminar la alerta',
      aEmails: 'Correos adicionales',
      updateAlerts: 'Cambia de plan para poder crear alertas',
      completedDateSet: 'Fecha actualizada'
    },

    // reviews view
    reviews: {
      title: 'Procesos para Revisión',
      tableTitle: 'Por revisar',
      reject: 'Rechazar',
      approve: 'Aprobar',
      approveDialogTitle: '¿Quieres aprobar este proceso?',
      approveDialogText: 'El proceso con título {title} será aprobado para generación. Por favor asegurate que la información este correcta.',
      rejectDialogTitle: '¿Quieres rechazar este proceso?',
      rejectDialogText: 'El proceso con título {title} será rechazado y mandado a revisar. Por favor asegurate que la información este correcta y que la razón de rechazo se entienda.',
      checkErrors: 'Por favor revisa el formulario por errores',
      approveSuccess: '¡El proceso ha sido aprobado exitosamente!',
      approveFail: 'Ocurrió un error aprobando el proceso',
      resonRejection: 'Por favor escribe una razón de rechazo',
      rejectSuccess: '¡El proceso ha sido rechazado exitosamente!',
      rejectFail: 'Ocurrió un error rehazando el proceso',
      errorDecrypt: 'Error al interpretar datos',
      reason: 'Razón de rechazo',
      creator: 'Creador'
    },

    // myOrgComponent
    myOrg: {
      manageUsers: 'Administrar Usuarios',
      manageClientsAndProjects: 'Clientes y Proyectos',
      manageESignatures: 'Firma Electrónica',
      newClientTitle: 'Nuevo Cliente',
      createClient: 'Guardar',
      deleteClientDialogTitle: '¿Quieres eliminar este cliente?',
      deleteClientDialogText: 'El cliente {name} será eliminado. Ya no se podrán hacer nuevas referencias en el futuro.',
      deleteClientSuccess: '¡Cliente eliminado!',
      deleteClientError: 'Ocurrió un error al eliminar el cliente',
      editClientTitle: 'Modificar',
      editClientSuccess: '¡Cliente modificado!',
      editClientError: 'Ocurrió un error al modificar el cliente',
      individual: 'Individual',
      individualHint: 'El usuario solamente tendrá acceso a los procesos que inició',
      inviteTitle: 'Invita un usuario',
      usersInOrgTitle: 'Usuarios en tu organización',
      reviewer: 'Revisor',
      creator: 'Creador',
      manager: 'Manager',
      owner: 'Owner',
      consultant: 'Consultor',
      roles: 'Roles',
      hasAccepted: 'Ha Aceptado',
      inviteUser: 'Invitar Usuario',
      oneRole: 'Por favor seleccione al menos un rol',
      inviteDialogTitle: '¿Quieres invitar un usuario a tu organización?',
      inviteDialogText: 'El usuario con correo electrónico {email} recibirá una invitación para unirese a tu organización. Por favor verifica la dirección de correo electrónico. Este usuario obtendrá acceso a las funciones de tu organización al aceptar.',
      invite: 'Invitar',
      active: 'Activos',
      billing: 'Facturando',
      editRoles: 'Editar roles',
      deleteDialogTitle: '¿Quieres eliminar el usuario de tu organización?',
      deleteDialogText: 'El usuario con correo electrónico {email} será eliminado y no tendrá acceso a las funciones de tu organización.',
      remove: 'Eliminar',
      inviteError: 'Error al invitar usuario',
      inviteUserErrorLimit: 'Agrega usuarios a tu suscripción',
      inviteSuccess: 'Usuario invitado exitosamente',
      removeError: 'Error eliminando usuario',
      removeSuccess: 'Usuario eliminado exitosamente',
      editFail: 'Error al modificar usuario',
      editSuccess: 'Usuario modificado exitosamente',
      projects: 'Proyectos',
      deleteProjectDialogTitle: '¿Quieres eliminar este proyecto?',
      deleteProjectDialogText: 'El proyecto {name} será eliminado. Esto no afectará a los procesos existentes, pero el filtro dejara de estar disponible en el tablero.',
      delete: 'Eliminar',
      deleteProjectSuccess: '¡Proyecto eliminado!',
      deleteProjectError: 'Ocurrió un error al eliminar el proyecto',
      newProjectDialogTitle: 'Crear proyecto',
      createProject: 'Crear proyecto',
      createProjectSuccess: 'Proyecto creado exitosamente',
      createProjectError: 'Ocurrió un error al crear el proyecto',
      createProjectErrorExists: 'Existe otro proyecto con el mismo nombre',
      appSettings: {
        title: 'Configuración App',
      },
      clients: {
        title: 'Clientes',
        createSuccess: 'Clientre creado exitosamente',
        createError: 'Ocurrió un error al crear el cliente',
        createErrorExists: 'El cliente ya existe',
        deleteSuccess: 'Cliente eliminado exitosamente',
        deleteError: 'Ocurrió un error al eliminar el cliente',
      },
      teams: {
        title: 'Equipos de trabajo',
        members: 'Integrantes',
        newTitle: 'Crear nuevo Equipo',
        create: 'Crear equipo',
        createExists: 'El nombre del equipo ya existe, utiliza otro',
        createError: 'Ocurrió un error al crear el Equipo',
        createSuccess: 'Equipo de trabajo creado exitosamente',
        delTitle: '¿Quieres eliminar este equipo?',
        delText: 'El dueño de la organización continuará teniendo acceso a los procesos asociados con el equipo.',
        delTeamText: 'Los siguientes usuarios dentro de la organización perderán acceso a los procesos, en cualquier estado, asociados con el equipo',
        del: 'Eliminar Equipo',
        confirmDel: 'Escriba "BORRAR" para continuar:',
        confirmDelWord: 'BORRAR',
        delTeamSuccess: 'Equipo eliminado exitosamente',
        delTeamError: 'Ocurrió un error al eliminar el equipo',
        teamNotSelected: 'No hay equipo seleccionado',
        editTitle: 'Configuración de Equipo',
        editSave: 'Guardar',
        addMember: 'Agregar Integrante',
        add: 'Agregar',
        invalidNewMember: 'El nuevo integrante es inválido',
        invalidTeamName: 'El nombre del equipo es inválido',
        addMemberTeamError: 'Ocurrió un error al agregar integrante (error equipo)',
        addMemberRoles: 'Ocurrió un error al agregar integrante (rol)',
        addMemberExists: 'El integrante ya existe en el equipo',
        addMemberError: 'Ocurrió un error al agregar nuevo integrante',
        addMemberSuccess: '¡Se agregó nuevo integrante!',
        delMemberNotSelected: 'Equipo o intengrante no seleccionado',
        delMemberSuccess: '¡Integrante eliminado!',
        delMemberTeamError: 'Ocurrió un error al eliminar integrante (error equipo)',
        delMemberRoles: 'Integrante no pertenece a la organización',
        delMemberNotExists: 'El integrante no existe en el equipo',
        delMemberError: 'Ocurrió un error al eliminar al integrante del equipo',
        delMemberTitle: '¿Quieres eliminar este miebro del equipo?',
        delMemberText: 'El usuario {email} será eliminado del equipo {team}.',
        delMember: 'Eliminar integrante',
        preDelError: 'Ocurrió un error al traer info previa',
        inviteNotAccepted: 'El usuario debe aceptar la invitación de unirse a la organización antes de agregarlo al equipo.'
      },
      theme: {
        set: 'Establecer Tema',
        reset: 'Colores Originales'
      },
      electronicSignatures: 'Firmas electrónicas'
    },
    // createOrJoinOrg component
    createOrgs: {
      createTitle: 'Crea una nueva organización',
      createOrg: 'Crear Organización',
      invitationsTitle: 'Invitaciones de otras organizaciones',
      orgName: 'Nombre',
      roles: 'Role(s)',
      actions: 'Acciones',
      acceptDialogTitle: '¿Quieres aceptar esta invitación?',
      acceptDialogText: 'Te unirás a la organización de {name}',
      createDialogTitle: '¿Quieres crear esta organización?',
      createDialogText: 'Al crear una organización, obtienes el rol de dueño sobre esta. Eres responsable del uso correcto en acuerdo con los Términos y Condiciones. El uso incorrecto resultará en la deshabilitación de tu cuenta y de la organización.',
      create: 'Crear',
      formError: 'Por favor revisar formuilario',
      createSuccess: '¡La organización ha sido creada exitosamente!',
      createFail: 'Ocurrió un error al crear la organización',
      reqError: 'Error con el request',
      inviteError: 'Ocurrió un error al aceptar la invitación'
    },
    // manageTemplates view
    manageTemplates: {
      uploadTitle: 'Subir Plantilla',
      uploadedTitle: 'Plantillas subidas',
      uploadFile: 'Subir Documento',
      fileInput: 'Selecciona documento',
      uploadDialogTitle: '¿Quieres subir tu documento?',
      uploadDialogText: 'Usuarios con rol de creador podrán utilizar esta plantilla.',
      upload: 'Subir',
      deleteDialogTitle: '¿Quieres eliminar esta plantilla?',
      deleteDialogText: 'La plantilla {name} será eliminada. Procesos activos que la esten utilizando aún podrán ser trabajados.',
      delete: 'Eliminar',
      fileName: 'Seleccionar un documento',
      uploadSuccess: '¡Documento subido exitosamente!',
      uploadError: 'Ocurrió un error al subir el documento, por favor revisa los campos por errores.',
      deleteError: 'Ocurrió un error al eliminar la plantilla',
      deleteSuccess: '¡La plantilla fue eliminada exitosamente!',
      copyDialogTitle: 'Selecciona la plantilla de la cual copiar la configuración',
      copyDialogText: 'Esta acción copiara los elementos en común, descripción y directorio de la configuración de la plantilla seleccionada abajo:',
      copy: 'Copiar',
      copySuccess: 'Configuración copiada',
      copyError: 'Ocurrió un error al copiar la configuración'
    },
    // templateSettings
    templateSettings: {
      title: 'Configuración de {tName}',
      description: 'Descripción corta',
      setDescription: 'Establecer Descripción',
      directory: 'Directorio de la plantilla',
      setDirectory: 'Establecer Directorio',
      groupsAndOrder: 'Nombres de Grupos y Orden',
      setGroupsAndOrder: 'Establecer nombres y orden',
      dropdown: 'Campos desplegables',
      setDropdown: 'Establecer campos desplegables',
      noDropdownTags: 'Esta plantilla no contiene campos desplegables',
      placeholders: 'Etiquetas',
      placeholdersTitle: 'Cambio de nombres de etiquetas',
      setPlaceholders: 'Establecer nuevos nombres',
      hints: 'Pistas',
      hintsTitle: 'Cambio de pistas',
      setHints: 'Establecer nuevas pistas',
      descriptionDialogTitle: '¿Quieres establecer una nueva descripción?',
      descriptionDialogText: 'La descripción debe ser fácil de entender.',
      groupsDialogTitle: '¿Quieres establecer nuevos nombres de grupos y su orden?',
      groupsDialogText: 'Los nombres deben ser fáciles de entender.',
      dropdownDialogTitle: '¿Quieres establecer campos desplegables?',
      dropdownDialogText: 'Los campos deben ser escritos exactamente como se quisiera reemplazar dentro de las plantillas.',
      tagsDialogTitle: '¿Quieres establecer nuevas etiquetas?',
      tagsDialogText: 'Las etiquetas deben ser fáciles de entender.',
      hintsDialogTitle: '¿Quieres establecer nuevas pistas?',
      hintsDialogText: 'Las pistas deben ser cortas y fáciles de entender.',
      directoryDialogTitle: '¿Quieres establecer el directorio?',
      directoryDialogText: 'Esto sirve para cuando se usa la vista de directorio.',
      tagsError: 'Ocurrió un error al establecer etiquetas',
      tagsSuccess: '¡Las etiquetas se establecieron exitosamente!',
      hintsSuccess: 'Las pistas se establecieron exitosamente!',
      hintsError: 'Ocurrió un error al establecer pistas',
      groupsSuccess: 'Los grupos se establecieron exitosamente!',
      groupsError: 'Ocurrió un error al establecer grupos',
      dropdownSuccess: 'Los campos desplegables se establecieron exitosamente!',
      dropdownError: 'Ocurrió un error al establecer campos desplegables',
      descriptionSuccess: 'La descripción se estableció exitosamente!',
      descriptionError: 'Ocurrió un error al establecer la descripción',
      directorySuccess: 'El directorio se estableció exitosamente!',
      directoryError: 'Ocurrió un error al establecer el directorio',
      ungroupedFields: 'Campos sin grupo',
      generatedFields: 'Campos Auto-Generados',
      setGeneratedFields: 'Establecer campos',
      noGeneratedFields: 'Esta plantilla no contiene campos Auto-Generados',
      dateTags: 'Fecha',
      currencyTags: 'Moneda',
      numTags: 'Número',
      codeTags: 'Código',
      upperCase: 'Mayúscula',
      femenine: 'Femenino',
      generatedFieldsSub: 'Marcar para modificar la generación de campos',
      generatedDialogTitle: '¿Quieres guardar la configuración?',
      generatedDialogText: 'Los campos marcados serán generados en mayúsculas y/o en femenino.',
      setGenerated: 'Guardar',
      generatedSuccess: 'Se guardó la configuración exitosamente',
      generatedError: 'Ocurrió un error al guardar la configuración',
      createOptionGroupDialogTitle: 'Crear nuevo grupo de opciones',
      createOptionGroupDialogText: 'Escribe un nombre para el grupo, una pista si quieres, y por ultimo selecciona las opciones que quisieras agrupar.',
      createGroup: 'Crear Grupo',
      createGroupSuccess: 'Grupo de opciones creado',
      createGroupError: 'Ocurrió un error al crear el grupo de opciones',
      editOptionGroup: 'Guardar Grupo',
      editOptionGroupDialogTitle: 'Modifica el grupo de opciones',
      editOptionGroupSuccess: 'Grupo de opciones modificado',
      editOptionGroupError: 'Ocurrió un error al modificar el grupo de opciones',
      deleteOptionGroupDialogTitle: '¿Quieres eliminar el grupo de opciones?',
      deleteOptionGroupDialogText: 'El grupo de opciones `{name}` será eliminado',
      deleteOptionGroup: 'Eliminar',
      deleteOptionGroupSuccess: 'Grupo de opciones eliminado',
      deleteOptionGroupError: 'Ocurrió un error al eliminar el grupo de opciones',
      optionGroupsSuccess: 'Los grupos de opciones fueron guardados exitosamente',
      optionGroupsError: 'Ocurrió un error al guardar los grupos de opciones',
      setOptionGroups: 'Establecer grupos de opciones',
      optionGroupsDialogTitle: '¿Quieres guardar la configuración?',
      optionGroupsDialogText: 'Los grupos de opciones serán guardados y estarán disponibles para nuevos procesos',
      noOptions: 'Esta plantilla no contiene campos de opciones para poder agrupar',
      publicSharing: 'Compartir Formulario con Link',
      activated: 'Activo',
      deactivated: 'Inactivo',
      groups2share: 'Grupos para compartir',
      getPublicLink: 'Obtener Link',
      optionGrouping: 'Grupos de Opciones',
      optionGroupingDes: 'Agrupa opciones en un campo desplegable',
      general: 'General',
      placeholdersAndHints: 'Etiquetas y pistas',
      onlyMonth: 'Sólo el mes',
      attachments: 'Archivos adjuntos',
      upgradePublicForms: 'Cambia tu plan de suscripción para crear formularios públicos que puedes compartir con un link',
      upgradeFileAttachments: 'Cambia tu plan de suscripción para poder adjuntar archivos a los procesos',
      addAttachment: 'Agregar archivo adjunto',
      deleteAttachment: 'Eliminar archivo adjunto',
      duplicatedAttachment: 'El nombre para el archivo adjunto ya existe',
      autoApprove: 'Aprobar el proceso automáticamente',
      autoApproveCincel: 'Empieza proceso de firma automáticamente con CINCEL',
      autoApproveCincelSelect: 'Selecciona a las personas que firmen',
      notifications: {
        title: 'Notificaciones para cambios de estado',
        assigned: 'Proceso asignado',
        approved: 'Proceso aprobado',
        invalidEmail: 'Necesita ser un correo válido',
        set: 'Establecer notificaciones',
        placeHolders: 'Campos para compartir',
        emailsLabel: 'Correos a notificar'
      },
      attachments: {
        title: 'Archivos adjuntos',
        appendToDocument: 'Adjuntar a documento',
        public: 'Formulario Público',
        publicRequired: 'Requerido Formulario Público',
      }
    },
    projectSettings: {
      name: 'Nombre',
      addedOn: 'Agregado en',
      metrics: 'Métricas',
      title: 'Ajustes del proyecto {name}',
      metricDialogTitle: 'Agregar métrica a proyecto',
      addMetric: 'Agregar',
      addMetricSuccess: 'Se agregó la métrica exitosamente',
      addMetricError: 'Ocurrió un error al agregar métrica',
      deleteMetricDialogTitle: '¿Quieres eliminar la métrica?',
      deleteMetricDialogText: 'La métrica {name} será eliminada.',
      delete: 'Eliminar',
      deleteMetricSuccess: 'Se eliminó la métrica exitosamente',
      deleteMetricError: 'Ocurrió un error al eliminar la métrica',
      editMetricDialogTitle: 'Modificar la métrica {name}',
      editMetric: 'Modificar',
      editMetricSuccess: 'Se modificó la métrica exitosamente',
      editMetricError: 'Ocurrió un error al modificar la métrica'
    },
    // dashboard view
    dashboard: {
      title: 'Tablero',
      generatedDocuments: 'Documentos Generados',
      approvedProcesses: 'Procesos Aprobados',
      activeProcesses: 'Procesos Activos',
      activeProcessesStatus: 'Estado de Procesos Activos',
      approvalsTemplate: 'Aprobaciones por Plantilla',
      approvedProcessesUser: 'Procesos Aprobados por Usuario',
      activeProcessesUserStatusCreate: 'Detalle de procesos activos',
      selectProjects: 'Filtro por proyectos',
      notFound: 'No disponible',
      userNotFound: 'Ya no forma parte',
      createdSince: 'Creado hace (días)',
      selectUsers: 'Filtro por usuarios',
      dateRanges: {
        'Today': 'Hoy',
        'Yesterday': 'Ayer',
        'This week': 'Esta semana',
        'This month': 'Este mes',
        'This quarter': 'Este trimestre',
        'This year': 'Este año',
        'Last 7 days': 'Últimos 7 días',
        'Last 30 days': 'Últimos 30 días',
        'Last week': 'Semana pasada',
        'Last month': 'Mes pasado',
        'Last quarter': 'Trimestre pasado',
        'Last year': 'Año pasado'
      },
      timeGranularity: {
        'hour': 'Hora',
        'day': 'Día',
        'week': 'Semana',
        'month': 'Mes',
        'year': 'Año'
      },
      status: {
        'drafting': 'Borradores',
        'rejected': 'Rechazados',
        'reviewing': 'En revisión'
      },
      statusSingle: {
        'drafting': 'Borrador',
        'rejected': 'Rechazado',
        'reviewing': 'En revisión'
      }
    },
    // payments view
    payments: {
      title: 'Pagos y suscripción',
      currentSub: 'Tu suscripción actual contiene:',
      howMuch: 'Cuánto se te cobraría por:',
      users: 'Usuario | Usuarios',
      amount: '${amount}/mes',
      start: 'Empezar Suscripción',
      update: 'Actualizar Suscripción',
      updating: 'Actualizando tu suscripción',
      cancel: 'Cancelar Suscripción',
      moreUsers: '¿Necesitas más usuarios?',
      contactSales: 'Contacta al equipo de ventas y encontraremos la mejor opción para tí.',
      contact: 'Contactar Ventas',
      upgradeDialogTitle: '¿Quieres actualizar tu suscripción?',
      upgradeDialogText1: 'Se le hará un cargo a la tarjeta que termina con número {lastDigits}. El cargo será correspondiente al número de {pUsers}',
      upgradeDialogText2: 'extra equivalente a lo que queda para la próxima fecha de pago. Toma en consideración que a partir de la próxima fecha de pago, la cantidad por {nUsers} usuarios será cargada a la tarjeta.',
      downgradeDialogText: 'Estas reduciendo la cantidad de usuarios en tu suscripción. A partir de la próxima fecha de pago, se te hara el cobro por {nUsers}',
      downGradeLimit: 'Existen más usuarios activos en tu organización que la cantidad seleccionada. Por favor elimina {nUsers} de tu organización en la',
      organizationPage:'vista de organización.',
      confirmIns: 'Escribe "ACTUALIZAR" para continuar:',
      confirmWord: 'ACTUALIZAR',
      cancelDialogTitle: '¿Quieres cancelar tu suscripción?',
      cancelIns: 'Escribe "CANCELAR" para continuar:',
      cancelWord: 'CANCELAR',
      cancelSubscription: 'Cancelar Suscripción',
      updateSuccess: '¡Actualización exitosa! Tu información cambiará en algunos segundos',
      updateError: 'Actualización falló, prueba otra vez en un hora',
      disableSuccess: '¡Cancelación exitosa!',
      disableError: 'Cancelación falló',
      recurrente: {
        cancelIns: 'Puedes cancelar tu suscripción con nuestro partner de pagos en: ',
        updatePlanIns: 'Si quieres actualizar tu plan, porfavor contacta al equipo de soporte enviando un correo a: ',
        upgradeDialogTitle: '¿Quieres actualizar tu suscripción?',
      }
    },
    pricingM: {
      current: 'Actual',
      orderSupport: 'Para administrar tu orden, suscripción, invoice y detalles de pago, accede a la página de nuestro afiliado de pagos:',
      orderSupportLink: 'https://secure.2co.com/myaccount/',
      currentPlan: 'Plan Actual',
      updatePlan: 'Actualizar Plan',
      updatePlanSub: 'Actualizar al plan de Suscripción {plan}',
      updatePlanText: 'Estás a punto de cambiar tu plan de suscripción al plan {plan}.',
      updateDialogText1: 'La tarjeta que termina con {lastDigits} será cobrada por una cantidad calculdada correspondiente al nuevo plan y cantidad de usuarios seleccionados, teniendo en consideración el tiempo para la próxima fecha de pago. A partir de la próxima fecha de pago, se le cobrará la cantidad completa.',
      updateDialogText2: 'La tarjeta que termina con {lastDigits} podría ser cobrada por una cantidad calculdada correspondiente al nuevo plan y cantidad de usuarios seleccionados, teniendo en consideración el tiempo para la próxima fecha de pago. A partir de la próxima fecha de pago, se le cobrará la cantidad completa.',
      activeUserLimit: 'Existen más usuarios activos dentro de tu organización, que la cantidad seleccionada para actualizar la suscripción. Por favor elimina {nUsers} usuarios de tu organización en la',
      updateToOtherPlan: 'Actualizar plan de suscripción',
      updateCurrentPlan: 'Actualizar Plan Actual',
      updateCurrentPlanText: 'Estás a punto de cambiar la cantidad de usuarios en tu plan de suscripción actual a un total de {users}',
      choose: 'Tres opciones, escoge una.',
      noSize: 'Tenemos lo que necesitas.',
      monthly: 'Mensual',
      annual: 'Anual',
      priceUser: '/ usuario al mes',
      discount: 'Ahorra 20% con pagos anuales',
      bulkDiscount: 'Ahorra 10% adicional al adquirir más de 5 usuarios',
      $: '$',
      priceUserYear: ' / usuario al año',
      priceTotalMonth: 'total al mes',
      priceTotalYear: 'total al año',
      minimum: 'Incluye {n} usuarios',
      additionalUsers: '{price} extra por usuario adicional',
      functions: 'Funcionalidades',
      comparison: 'Comparación de Planes',
      coming: 'Próximamente',
      functionalities: [
        'Acceso a las plantillas pre-cargadas',
        'Procesos ilimitados',
        'Plantillas personalizadas ilimitadas',
        'Soporte estándar',
        'Soporte dedicado',
        'Proceso de Revisión',
        'Word Add-in',
        'Dashboard',
        'Sistema de Alertas',
        'Generación de contratos en grupo',
        'Compartir Formularios a terceros',
        'Firma Electrónica',
        'Consulta RGP-GT',
        'Planificador',
        'Flujos de trabajo personalizados',
        'Automatización de plantillas con IA'
      ],
      functionalitiesDescribed: {
        'Acceso a las plantillas pre-cargadas': 'Utiliza nuestras plantillas listas para su uso.',
        'Procesos ilimitados': 'Crea los procesos que necesites para generar contratos.',
        'Plantillas personalizadas ilimitadas': 'Sube las plantillas que quieras, exclusivas solo para tu uso.',
        'Soporte estándar': 'Soporte por chat y correo.',
        'Soporte dedicado': 'Canal de soporte directo.',
        'Proceso de Revisión': 'Reduce errores al mandar a revisar tus procesos antes de generar los contratos.',
        // eslint-disable-next-line
        'Dashboard': 'Visualiza en qué está trabajando tu equipo y planea para el futuro.',
        'Sistema de Alertas': 'Crea alertas y recordatorios de plazos a vencer para dar un mejor servicio a tu cliente.',
        'Generación de contratos en grupo': 'Genera un grupo de documentos a partir de un archivo Excel.',
        'Compartir Formularios a terceros': 'Adquiere información directamente desde tus clientes o terceros de una forma fácil y segura.',
        'Word Add-in': 'Crea plantillas personalizadas con nuestro Add-in, disponible en la tienda Office.',
        'Firma Electrónica': 'Completa tu proceso legal con integración de firma electrónica. Redacta y firma en un mismo lugar.',
        'Consulta RGP-GT': 'Automatiza la consulta al Registro General de la Propiedad de Guatemala, recibe los documentos en tu correo',
        'Planificador': 'Genera azimuts para multiples planos a partir de un archivo de Excel',
        'Flujos de trabajo personalizados': 'Aplica pasos y requerimientos para aprobar un proceso y luego generarlo.',
        'Automatización de plantillas con IA': 'Hace que las plantillas personalizadas sean más fáciles al escanear tus documentos con reconocimiento de entidades usando algoritmos de IA.'
      },
      planDict: {
        basic: 'Básico',
        standard: 'Estándar',
        advanced: 'Avanzado'
      },
      plans: [
        {
          title: 'Básico',
          key: 'basic',
          price: ['150', '1440'],
          priceUserYear: ['300', '240'],
          priceUser: ['25', '20'],
          minUsers: '5',
          details: {
            'Acceso a las plantillas pre-cargadas': true,
            'Procesos ilimitados': true,
            'Plantillas personalizadas ilimitadas': true,
            'Soporte estándar': true,
            'Word Add-in': true,
            'Firma Electrónica': true,
            'Consulta RGP-GT': false,
            'Planificador': false,
            'Proceso de Revisión': false,
            // eslint-disable-next-line
            'Dashboard': false,
            'Sistema de Alertas': false,
            'Generación de contratos en grupo': false,
            'Compartir Formularios a terceros': false,
          }
        },
        {
          title: 'Estándar',
          key: 'standard',
          price: ['250', '4320'],
          priceUserYear: ['420', '336'],
          priceUser: ['35', '28'],
          minUsers: '10',
          details: {
            'Acceso a las plantillas pre-cargadas': true,
            'Procesos ilimitados': true,
            'Plantillas personalizadas ilimitadas': true,
            'Soporte estándar': true,
            'Word Add-in': true,
            'Firma Electrónica': true,
            'Consulta RGP-GT': true,
            'Planificador': true,
            'Proceso de Revisión': true,
            // eslint-disable-next-line
            'Dashboard': true,
            'Sistema de Alertas': true,
            'Generación de contratos en grupo': false,
            'Compartir Formularios a terceros': false,
            // eslint-disable-next-line
            'Flujos de trabajo personalizados': 'inProgress',
            'Automatización de plantillas con IA': 'inProgress'
          }
        },
        {
          title: 'Avanzado',
          key: 'advanced',
          price: ['450', '4430'],
          priceUserYear: ['540', '432'],
          priceUser: ['45', '36'],
          minUsers: '20',
          details: {
            'Acceso a las plantillas pre-cargadas': true,
            'Procesos ilimitados': true,
            'Plantillas personalizadas ilimitadas': true,
            'Soporte dedicado': true,
            'Word Add-in': true,
            'Firma Electrónica': true,
            'Consulta RGP-GT': true,
            'Planificador': true,
            'Proceso de Revisión': true,
            // eslint-disable-next-line
            'Dashboard': true,
            'Sistema de Alertas': true,
            'Generación de contratos en grupo': true,
            'Compartir Formularios a terceros': true,
            // eslint-disable-next-line
            'Flujos de trabajo personalizados': 'inProgress',
            'Automatización de plantillas con IA': 'inProgress'
          }
        }
      ],
      // template customization
      templateCustomization: 'Perzonalización de Plantillas',
      customizationSlogan: '¿No quieres crear tus plantillas?',
      customizationSlogan2: 'No te preocupes.',
      customizationDes: 'Ofrecemos el servicio de personzalización de plantillas. Asumimos responsabilidad para cumplir con tus requerimientos y garantizamos calidad para tus plantillas. La ventaja es que una vez creada, la puedes usar cuantas veces quieras.',
      customizationPrice: '99',
      customizationPriceDes: 'por plantilla',
      customizationSteps: ['Identificación de documento base', 'Definición de casos de uso', 'Definición de campos', 'Dos revisiones', 'Entrega de plantilla'],
      
      // freeAccount
      callToAction: '¡Verifica tu número de teléfono para usar tu cuenta gratis!',
      freePlan: {
        title: 'Plan Gratis',
        key: 'free',
        price: ['0', '0'],
        priceUserYear: ['0', '0'],
        priceUser: ['0', '0'],
        minUsers: '1',
        details: {
          'Acceso a las plantillas pre-cargadas': true,
          '10 procesos al mes': true,
          'Soporte estándar': true,
        }
      },
      startFreePlan: 'Comenzar Plan Gratuito',
      setFreeAccountError: 'Ocurrió un error, por favor contacta a soporte',
      confirmCodeErrors: {
        alreadyInUse: 'El número de teléfono ya esta en uso'
      },
      confirmCodeSuccess: 'Tu teléfono ha sido verificado',
      translationsPhone:{
        countrySelectorLabel: 'Código de país',
        countrySelectorError: 'Escoge un país',
        phoneNumberLabel: 'Número de teléfono',
        example: 'Ejemplo:'
      },
      // single User
      singleTitle: '¿Necesitas ún solo usuario?',
      singlePrice: '45',
      perMonth: 'al mes',
      singleFeatures: {
        'Acceso a las plantillas pre-cargadas': true,
        'Procesos ilimitados': true,
        'Plantillas personalizadas ilimitadas': true,
        'Soporte estándar': true,
        // eslint-disable-next-line
        'Word Add-in': true,
      },
      signUp: 'Regístrate'
    },
    // myAccount view
    myAccount: {
      title: 'Mi cuenta',
      account: 'Mi cuenta',
      deactivate: 'Desactivar',
      accountDetails: 'Configuración de cuenta',
      resetPassword: 'Reestablecer contraseña',
      resetPasswordIns: 'Haz click en el botón para enviar un correo para reestablecer tu contraseña',
      resetPasswordBtn: 'Mandar correo',
      mfa: 'Autenticación Multi-factor',
      mfaIns: 'Aumenta la seguridad de tu usuario con autenticación Multi-factor. Obtendrás un código de seguridad cada vez que necesites ingresar.',
      mfaIns2: 'Selecciona el código de país e ingresa tu número de teléfono. Recibirás un código de confirmación por medio SMS.',
      mfaIns3: 'Ingresa el código de confirmación que recibas en tu teléfono.',
      mfa: 'Multi-factor Authentication',
      startMFA: 'Empezar',
      enrollMFA: 'Vincular MFA',
      unenrollMFA: 'Desvincular MFA',
      deactivateTitle: 'Desactivar tu cuenta',
      deactivateIns: 'Haz click en el botón de abajo si quieres desactivar tu cuenta',
      deactivateBtn: 'Desactivar tu cuenta',
      organizationTitle: 'Abandonar tu organización',
      organizationIns: 'Haz click abajo si quieres abandonar tu organización',
      leaveOrg: 'Abandonar Organización',
      organizationInsOwner: 'Para poder abandonar tu organización, tienes que transferir la propiedad de tu organización a un usuario que sea parte de tu organización. Por favor escribe el correo electronico del usuario al cual le quieres transferir los derechos:',
      emailTransfer: 'Correo para transferir tus derechos',
      transferOwner: 'Transferir derechos',
      leaveOrgTitle: '¿Quieres abandonar esta organización?',
      leaveOrgText: 'Perderás acceso a los recursos de la organización. Al completar el proceso, podrás aceptar una nueva invitación para unirte a una organización, ó crear una nueva.',
      leaveOrgConfirm: 'Por favor escribe el nombre de tu organización actual para proceder: {orgName}',
      transferOrgTitle: '¿Quisieras transferir la propiedad de tu organización?',
      transferOrgText: 'Tendrás todos los roles, pero perderás la capacidad de administrar la organización. Los derechos para administrar la organización serán transferidos al usuario con el correo de {email}.'
    },
    thankYou: {
      title: 'Gracias por confiar en nosotros!',
      msg: 'Puede tomar unos segundos para ver los cambios reflejados en tu organización.',
      msg2: 'En algunas situaciones, los pagos pueden ser retrasados hasta 10 minutos, apreciamos tu paciencia.',
      redirect: 'Serás redirigido al menú de inicio en 5 segundos.'
    },
    thankYouSign: {
      title: 'Revisa tu buzón',
      msg: '¡Felicidades por tu nueva cuenta! Este es el primer paso para realizar procesos más simples y productivos.',
      msg2: 'Por favor verifica tu correo.',
    },
    emailVerify: {
      title: '¡Tu correo ha sido verificado!',
      btn: 'Ir a inicio de sesión',
      expired: 'Este enlace ha expirado, porfavor envía otro proceso de verificación al iniciar tu sesión.'
    },
    passwordReset: {
      title: 'Establecer nueva contraseña',
      newPass: 'Nueva contraseña',
      setPass: 'Establecer contraseña',
      expired: 'Este enlace ha expirado, porfavor envía otro proceso para cambiar tu contraseña.',
      btn: 'Ir a inicio de sesión'
    },
    MFAunenroll: {
      title: 'El factor de autenticación ha sido desviculado',
      btn: 'Ir a inicio de sesión',
      expired: 'Este enlace ha expirado, inicia sesión para desvincular la autenticación Multi-factor.'
    },
    incompleteUser: {
      title: 'Completa tu info',
      text: 'Por favor completa la información para poder utilizar tu cuenta',
      setUp: 'Completar info',
      needPassword: 'Necesitamos que establezcas una contraseña para tu cuenta. Un correo para establecer tu contraseña será enviado.',
      passResetEmailSent: 'Correo enviado exitosamente, por favor revisa tu buzón',
      passResetEmailError: 'Ocurrió un error al mandar el correo, si el problema persiste contacta a soporte',
      success: 'Info actualizada',
      error: 'Si el problema persiste, contacte a soporte'
    },
    rgp:{
      title: 'Registro General de la Propiedad de Guatemala (BETA)',
      description: 'Inicia una consulta automática y recibe los archivos en tu correo.',
      user: 'Usuario',
      userExample: 'usuario@ejemplo.com',
      pass: 'Contraseña', 
      finca: 'Finca',
      folio: 'Folio', 
      libro: 'Libro',
      departamento: 'Departamento',
      startQuery: 'Iniciar Consulta',
      confirmation: 'Confirmación',
      msg1: 'Se consumirá un crédito de consulta de su cuenta. Se consolidará un archivo .zip que incluye los documentos e imagenes del registro. Esta operación puede demorar varios minutos, dependiendo del número de archivos encontrados.',
      msg2: '¿Deseas iniciar un proceso de consulta automática en el Registro General de la Propiedad de Guatemala?',
      historyTitle: 'Historial de últimos 30 días',
      getAll: 'Descargar todos los documentos de soporte',
      unstableRGP: 'La página del RGP de Guatemala está inestable, lamentamos los inconvenientes. Estamos trabajando en lo posible para volver a la normalidad. Los documentos en trámite no permiten ser descargados. Documentos de soporte con gran cantidad de páginas iniciarán una "solicitud de descarga" (nueva función del RGP). Adicionalmente, por la inestabilidad cualquier descarga de documentos puede fallar.'
    },
    batchGenerate: {
      instructions: 'Selecciona un archivo de Excel con extensión xlsx.',
      getTemplate: '¡Haz click aquí para el formato!',
      generate: 'Generar Grupo',
      send2unnasigned: 'Crear procesos en la plataforma, sin asignar'
    },
    docuSign: {
      signIn: 'Iniciar Sesión',
      unlink: 'Desvincular Cuenta'
    },
    cincel: {
      instructions: 'Ingresa tu llave API:',
      currentAPI: 'LLave API actual:',
      apiKey: 'Llave API',
      link: 'Vincular Cuenta',
      unlink: 'Desvincular Cuenta',
      getApiKey: 'Obtener nueva llave API',
      sign: 'Firma con Cincel',
      process: 'Proceso de firma con Cincel',
      signed: 'Firmado',
      unsigned: 'Sin firmar',
      stage: 'Etapa',
      accepted: 'Aceptado',
      signedFile: 'Documento Firmado',
      evidenceFile: 'Documento de evidencia',
      start: 'Empezar nuevo proceso de firma',
      invitations: 'Invitaciones',
      startProcess: 'Empezar proceso',
      minInvite: 'Se necesita agregar por lo menos una invitación',
      success: 'Proceso iniciado',
      error: 'Ocurrió un error',
      idVerification: 'Verificación de Identidad',
      repeatedEmails: 'No es posible repetir correos'
      
    },
    shared: {
      sharedProcesses: 'Procesos con Formularios compartidos',
      shareDialogTitle: '¿Quieres compartir tus respuestas?',
      shareDialogText: 'La información ingresada será compartida con {orgName} y sus miembros.',
      sendAnswers: 'Enviar Respuestas'
    },
    planifier: {
      title: 'Planificador-GT',
      description: 'Convierte instantáneamente la información de múltiples planos a texto',
      instructions: 'Sube un archivo Excel sin encabezados y horizontalmente escribe la información de los puntos en este orden:',
      info: ['De', 'Hacia', 'Minutos', 'Grados', 'Segundos', 'Distancia', 'Colindancia'],
      generate: 'Generar Texto',
      results: 'Resultados',
      from: 'De',
      to: 'Hacia',
      minutes: 'Minutos',
      degrees: 'Grados',
      seconds: 'Segundos',
      distance: 'Distancia',
      adjoining: 'Colindancia',
      points: [
        {'from': '0', 'to': '1', 'degrees': '80', 'minutes': '19', 'seconds': '48', 'distance': '174.50', 'adjoining': 'lote cuatro'},
        {'from': '1', 'to': '2', 'degrees': '338', 'minutes': '33', 'seconds': '7', 'distance': '6.15', 'adjoining': 'finca el cerro'}, 
        {'from': '2', 'to': '3', 'degrees': '260', 'minutes': '19', 'seconds': '48', 'distance': '174.22', 'adjoining': 'lote tres'}, 
        {'from': '3', 'to': '0', 'degrees': '162', 'minutes': '26', 'seconds': '57', 'distance': '6.05', 'adjoining': 'calle Real'}, 
        null, 
        {'from': '0', 'to': '1', 'minutes': '90', 'degrees': '1', 'seconds': '30', 'distance': '20.5', 'adjoining': 'casa cinco'}, 
        {'from': '1', 'to': '2', 'minutes': '350', 'degrees': '45', 'seconds': '20', 'distance': '10', 'adjoining': 'casa tres'}, 
        {'from': '2', 'to': '3', 'minutes': '270', 'degrees': '90', 'seconds': '22', 'distance': '15', 'adjoining': 'boulevar principal'}, 
      ],
      format: 'Formato',
      normal: 'Normal',
      alt1: 'Alt-1',
      style: 'Estílo de redacción',
      currencyType: 'Tipo de moneda',
      distanceSymbol: 'Símbolo de distancia',
      areaSymbol: 'Símbolo de área',
      symbols: 'Con Símbolos',
      USD: 'Dólares',
      GTQ: 'Quetzales',
      includeLetters: 'Numerar secciones con letras',
      convertColindancia: 'Convertir números y códigos de las colindancias a letras automáticamente (BETA)',
      upperCaseColidancia: 'Convertir las colindancias en mayúsculas',
      convertTitle: 'Convertir números y códigos de los títulos a letras automáticamente (BETA)',
      upperCaseTitle: 'Convertir el título a mayúsculas',
      titleInstructions: 'Si quieres agregar un título, puedes agregar los siguientes datos en la primera fila de cada plano en el siguiente orden: título*, valor, metraje total*, metraje del polígono. Los datos con * son requeridos si se usa esta opción.',
      downloadTemplate: 'Formato de ejemplo',
      error: 'Ocurrió un error, revisa los datos del archivo'
    },
    alerts: {
      title: 'Alertas',
      description: 'Administra alertas y recordatorios',
      alertDate: 'Fecha de la alerta',
      create: 'Crea una Alerta',
      upcoming: 'Alertas próximas',
      past: 'Alertas pasadas',
      dateMissing: 'Selecciona una fecha',
      checkFields: 'Revisa los campos',
      process: 'Proceso asociado',
      client: 'Cliente asociado',
      project: 'Proyecto asociado',
      createDate: 'Fecha de creación',
      message: 'Mensaje',
      alertEmails: 'Correos a alertar',
      updated: 'Alerta actualizada',
      updatedError: 'Error al actualizar',
      completedBy: 'Marcado como completado por: {user}'
    },
    freeTrial: {
      CTA: '15 días GRATIS',
      CTA2: 'Sin tarjetas de crédito, sin costos escondidos, sin compromiso.',
      instructions: 'Llena el formulario, invita a tus colegas y explora tu potencial.',
      userInfo: 'Usuario',
      orgInfo: 'Organización',
      start: 'Comenzar prueba'
    },
    reports: {
      title: 'Reportes',
      fromDate: 'Fecha inicio',
      toDate: 'Fecha final',
      generate: 'Generar Reporte'
    },
  }
}
messages = merge(messages, login)
// console.log(messages)

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages // set locale messages
})

export default i18n
