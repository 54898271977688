<template>
  <div id="rectangles" >
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
    <div class="rectangle" :style="{background: background}"></div>
  </div>

</template>
<script async src="https://cpwebassets.codepen.io/assets/embed/ei.js"></script>
<script>
export default {
  props: {
  },
  data: () => ({
    rectangleCount: 50,
    swayType: "sway-left-to-right",
    background: process.env.VUE_APP_THEME_RECTANGLES == null ? '#196293' : process.env.VUE_APP_THEME_RECTANGLES
  }),
  methods: {
    randomRange(min, max){
      var rand = Math.random()
      var randomRange = min + Math.floor(rand * ((max-min)+1))
      return randomRange
    },
    sample(list){
      var rand = Math.random()
      Math.round(rand * (list.length-1))
    }
  }

}
</script>

<style lang="scss">
// #rectangles {
//   background: #1a1e23;
//   margin: 0;
// }

$rectangle-count: 50;
$sway-type: "sway-left-to-right", "sway-right-to-left";
$float-type: "float-left", "float-right";
$opacity-options: 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9;

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

@function sample($list) {
  @return nth($list, random(length($list)));
}

#rectangles {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.rectangle {
  position: absolute;
  // left: var(--bubble-left-offset);
  top: var(--bubble-top-offset);
  // bottom: -100%;
  left:var(--bubble-left-offset);
  display: block;
  width: var(--bubble-width);
  height: var(--bubble-height);
  border-radius: 10px;
  animation: var(--bubble-float-type) var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite alternate;
  opacity: var(--bubble-opacity);
  z-index: 0;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: #196293;
    border-radius: inherit;
    // animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
  }

  @for $i from 0 through $rectangle-count {
    &:nth-child(#{$i}) {
      --bubble-left-offset: #{random_range(-30vw, 130vw)};
      --bubble-top-offset: #{random_range(0vh, 100vh)};
      --bubble-height: #{random_range(3vw, 4.5vw)};
      --bubble-width: #{random_range(6vw, 12vw)};
      --bubble-float-duration: #{random_range(40s, 300s)};
      --bubble-sway-duration: #{random_range(10s, 40s)};
      --bubble-float-delay: #{random_range(0s, 4s)};
      --bubble-sway-delay: #{random_range(0s, 4s)};
      --bubble-sway-type: #{sample($sway-type)};
      --bubble-float-type: #{sample($float-type)};
      --bubble-opacity: #{sample($opacity-options)}
    }
  }
}


@keyframes float-right {
  to {
    transform: translateX(175vw);
  }
}
@keyframes float-left {
  to {
    transform: translateX(-175vw);
  }
}

@keyframes float-up {
  to {
    transform: translateY(-175vh);
  }
}

@keyframes sway-left-to-right {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes sway-right-to-left {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-50%);
  }
}
</style>
